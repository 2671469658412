import styled from 'styled-components';

export const Airplane = styled.div`
  position: absolute;
  z-index: 11;
  height: 400px;
  width: 400px;
  bottom: -400px;
  left: 50%;
  background-image: url('/assets/images/intro/airplane.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`