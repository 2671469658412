import styled from 'styled-components';

export const ManFront = styled.div`
  position: absolute;
  height: 100%;
  top: 0px;
  width: 45%;
  left: 55%;
  opacity: 0;
  background-image: url(/assets/images/good1/man_front.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
`