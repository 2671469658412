import styled, { keyframes } from 'styled-components';

const slideOutTop = keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
`;

const slideInBottom = keyframes`
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

export const Scenario = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 100px;
  height: calc(100% - 100px);

  &.page-enter {
    animation: ${slideInBottom} 1.5s forwards;
  }
  &.page-exit {
    animation: ${slideOutTop} 1.5s forwards;
  }
`;