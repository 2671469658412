import styled from 'styled-components';

export const WomanYellow = styled.div`
  position: absolute;
  height: 85%;
  bottom: -100%;
  width: 40%;
  left: 55%;
  background-image: url(/assets/images/bad2/woman_yellow.png);
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
`