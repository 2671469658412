import styled from 'styled-components';

export const IPad = styled.div`
  position: absolute;
  height: calc(90% - 80px);
  bottom: 0%;
  right: -50%;
  width: 45%;
  background-image: url('/assets/images/intro/ipad.png');
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
`