import styled from 'styled-components';

export const SidebarContent = styled.div`
  position: absolute;
  top: 0;
  right: -360px;
  height: 100%;
  width: 360px;
  background-color: white;
  transition: right 1s;
`