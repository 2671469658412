import styled from 'styled-components';

export const ComingSoon = styled.div`
  position: absolute;
  top: 15%;
  right: 10%;
  opacity: 0;
  z-index: 1;
  color: #3C3C3C;
  font-size: 50px;
  font-weight: 900;
  letter-spacing: 1.43px;
  line-height: 68px;
  text-align: center;
`