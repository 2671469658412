import styled from "styled-components";

export const Row = styled.div`
  display: grid;
  grid-template-columns: 10% 45% 30% 15%;
  align-items: center;
  margin: 0.5em 0;
  box-sizing: border-box;
  border: 2px solid #E9E9E9;
  border-radius: 10px;
  background-color: #FFFFFF;
`

export const Cell = styled.div`
  border: none;
  padding: 1em 0em;
  color: #1C1C1C;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;

  &:first-child, &:last-child {
    text-align: center;
  }
  &:last-child {
    display: flex;
  }
`;

export const LinkButton = styled.div`
  height: 45px;
  width: 45px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
  background-image: url('assets/images/home/link.svg');
  margin-right: 1em;
  &:hover {
    background-color: rgb(118 118 118 / 0.15);
  }
`;
export const DeleteButton = styled.div`
  height: 45px;
  width: 45px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
  background-image: url('assets/images/delete.svg');

  &:hover {
    background-color: rgb(197 56 56 / 0.1);
  }
`;

export const Table = styled.div`
  margin-top: 4em;
  width: 100%;
  overflow: auto;
  max-height: 50vh;
`;
export const Header = styled.div`
  text-transform: uppercase;
  margin-bottom: 1em;
`;

export const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 10% 45% 30% 15%;
  width: 100%;
`;
export const HeaderCell = styled.div`
  text-align: left;
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
`;