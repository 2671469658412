export class ScenarioMapper {
  
  constructor(
    private readonly scenario: string
  ) {} 

  toPath() {
    switch(this.scenario) {
      case 'onboarding': return '2c38';
      case 'intro': return '5777';
      case 'good1': return '2ad2';
      case 'bad1': return 'a2ff';
      case 'good2': return '9ab5';
      case 'bad2': return '23d3';
      case 'good3': return '5bd6';
      case 'bad3': return '573e';
      case 'gym1': return '494c';
      case 'gym2': return '1a63';
      case 'blossom': return 'df44';
      case 'planner': return '9513';
      case 'champion': return '2e17';
      case 'expert': return 'ff3c';
      default: return '2c38';
    }
  }

  // static fromPath() {

  // }
}
