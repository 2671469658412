import styled from 'styled-components';

export const MessageText = styled.div<{ own: boolean }>`
  position: relative;
  border-radius: 5px;
  background-color: ${props => props.color};
  align-self: ${props => props.own ? 'flex-end' : 'flex-start'};
  padding: 1.5em;
  width: 270px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;

  & p {
    margin: 0.5em 0;
  }
`