import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

export const Scenario = types.model('Scenario')
  .props({
    id: types.string,
    scenarioId: types.string,
    answers: types.array(types.model({
      questionID: types.string,
      value: types.number,
      correctValues: types.optional(types.array(types.number),[])
    })) 
  })

export interface ScenarioInstance extends Instance<typeof Scenario> { }
export interface ScenarioSnapshotIn extends SnapshotIn<typeof Scenario> { }
export interface ScenarioSnapshotOut extends SnapshotOut<typeof Scenario> { }