import React from 'react';
import styled from 'styled-components';
import { Scenario } from './Scenario';
import { GameHeader } from './GameHeader';
import { SkyBackground } from './SkyBackground';

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 100px);
  width: 310px;
  margin: auto;
  text-align: center;
  font-size: 1.1em;
  line-height: 1.4em;
  font-weight: 300;
`

export const SmallDevicePage = () => (
  <Scenario style={{top: 0, height: '100%'}}>
    <GameHeader/>
    <SkyBackground style={{ backgroundSize: 'cover' }} />
    <Content>
      <div>Per un'esperienza ottimale di</div>
      <img width="270px" src="/assets/images/flyme_logo_negative.png" alt="FLYME TO THE MOON" style={{ margin: '1em 0' }} />
      <div>è necessario che la finestra di navigazione abbia una risoluzione minima di</div>
      <div><b>1024x650</b></div>
    </Content>
  </Scenario>
)