import React, { useState, Fragment } from 'react';
import { ChatIcon } from './ChatIcon';
import { Sidebar } from '../Sidebar';
import { ChannelMessages } from './ChannelMessages';
import { useParams } from 'react-router-dom';
import Game from '../Game';
import { NotReadBadge } from './NotReadBadge';
import { observer } from 'mobx-react';
import { ChatButton } from './ChatButton';
import { SidebarTitle } from '../Sidebar/SidebarTitle';
import { FormattedHTMLMessage } from 'react-intl';

export const Chat = observer<React.FC>(() => {
  const [ isSidebarOpen, toggleSidebar ] = useState(false);
  const { gameId } = useParams<{ gameId: string }>();
  return (
    <Game gameId={gameId || ''}>
      {
        ({ game }) => {
          isSidebarOpen && game.readChannel();
          return (game.state.channel && game.state.broadcast)
            ? (
              <Fragment>
                <ChatButton 
                  notRead={(game.state.channel.notReadCount + game.state.broadcast.notReadCount) > 0}
                  onClick={(e) => {
                    game.readChannel();
                    toggleSidebar(true);
                  }}>
                  <ChatIcon />
                  {(game.state.channel.notReadCount + game.state.broadcast.notReadCount) > 0
                    && <NotReadBadge>{game.state.channel.notReadCount + game.state.broadcast.notReadCount}</NotReadBadge>}
                </ChatButton>
                <Sidebar active={isSidebarOpen} onClose={() => toggleSidebar(false)}>
                  <SidebarTitle>
                    <span style={{ color: game.state.color }}>{game.state.teamName || game.state.name}</span> - <FormattedHTMLMessage id="chat.room" />
                  </SidebarTitle>
                  <ChannelMessages />
                </Sidebar>
              </Fragment>
            )
            : null
        }
      }
    </Game>
  )
})