import styled from 'styled-components';

export const QuestionDescription = styled.div`
  top: 20%;
  position: absolute;
  left: 100%;
  text-align: center;
  width: 100%;
  color: #143366;
  font-size: 2em;
  font-weight: 900;
  line-height: 1em;
`