import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { Sidebar } from '../Sidebar';
import { useParams } from 'react-router-dom';
import Game from '../Game';
import { MenuIcon } from './MenuIcon';
import { Scenario } from './Scenario';
import { SidebarTitle } from '../Sidebar/SidebarTitle';
import { FormattedHTMLMessage } from 'react-intl';

const ChatButton = styled.div`
  height: 60px;
  width: 60px;
  position: fixed;
  right: 2em;
  top: 11em;
  z-index: 20;
  background: #0046a9;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.08);

  &:hover {
    box-shadow: none;
  }

  & svg {
    fill: white;
    height: auto;
    width: 50%;
  }
`

export const History: React.FC = () => {
  const [isSidebarOpen, toggleSidebar] = useState(false);
  const [selectedScenario, setScenario] = useState('');
  const { gameId } = useParams<{ gameId: string }>();
  return (
    <Game gameId={gameId || ''}>
      {
        ({ game }) => (game.state.channel && game.state.broadcast)
          ? (
            <Fragment>
              <ChatButton onClick={() => toggleSidebar(true) }>
                <MenuIcon/>
              </ChatButton>
              <Sidebar active={isSidebarOpen} 
                onClose={() => {
                  toggleSidebar(false);
                  setScenario('');
                }}>
                <SidebarTitle>
                  <FormattedHTMLMessage id="scenarios.history.title"
                    defaultMessage="Your flight to the moon:" />
                </SidebarTitle>
                {
                  game.state.scenarios
                    .filter(scenario => scenario.scenarioId !== game.state.currentScenario )
                    .map( (scenario,index) => (
                      <Scenario key={index}
                        id={scenario.scenarioId}
                        active={selectedScenario === scenario.scenarioId}
                        questions={scenario.answers}
                        onClose={() => setScenario('')}
                        onSelect={() => setScenario(scenario.scenarioId)} />
                    ))
                }
              </Sidebar>
            </Fragment>
            
          )
          : null
      }
    </Game>
  )
}