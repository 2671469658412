import styled from 'styled-components';

export const Skyscraper = styled.div`
  position: absolute;
  height: 30%;
  width: 20%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
`