import styled from 'styled-components';

export const Sofa = styled.div`
  position: absolute;
  height: 100%;
  bottom: 0%;
  width: 55%;
  left: 40%;
  opacity: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
`