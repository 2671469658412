import React from "react";
import { FormattedHTMLMessage } from "react-intl";

import { Scenario } from "../Scenario";
import { Scene } from "../Scene";
import { AnimatablePage } from "../AnimatablePage";
import { QuestionsController } from "../QuestionsController";

import { TextStrip } from "../TextStrip";
import { ScenarioTitle } from "../ScenarioTitle";
import { TextBlock } from "../TextBlock";
import { Blur } from "../scenario3/Blur";
import { Office } from "../scenario3/Office";
import { BlankScreen } from "../scenario3/BlankScreen";
import { Charts } from "../scenario3/Charts";
import { Women } from "../scenario3/Women";
import { Room } from "../scenario3/Room";
import { Table } from "../scenario3/Table";
import { PC } from "../scenario3/PC";
import { Men } from "./elements/Men";
import SceneClass from "../SceneClass";

export default class Bad3 extends SceneClass {
  scene_1: HTMLDivElement | {} = {};
  blank_screen: HTMLDivElement | {} = {};
  charts: HTMLDivElement | {} = {};
  blur: HTMLDivElement | {} = {};
  women: HTMLDivElement | {} = {};

  scene_2: HTMLDivElement | {} = {};
  table: HTMLDivElement | {} = {};
  pc: HTMLDivElement | {} = {};
  men: HTMLDivElement | {} = {};
  
  textStrip: HTMLDivElement | {} = {};
  textBlock_1: HTMLDivElement | {} = {};
  textBlock_2: HTMLDivElement | {} = {};
  textBlock_3: HTMLDivElement | {} = {};
  textBlock_4: HTMLDivElement | {} = {};

  componentDidMount() {
    this.master
      .add('transition_1')
      .to(this.textStrip, this.timeFrame, { top: '0px' }, 'transition_1')
      .to(this.charts, this.timeFrame, { opacity: 1 }, 'transition_1')
      
      .add('transition_2')
      .to(this.blur, this.timeFrame * 0.5, { opacity: 1 }, 'transition_2')
      .to(this.blank_screen, this.timeFrame * 0.5, { opacity: 0 }, `transition_2`)
      .to(this.charts, this.timeFrame * 0.5, { opacity: 0 }, `transition_2`)
      .to(this.women, this.timeFrame * 0.7, { bottom: 0 }, `transition_2+=${this.timeFrame * 0.3}`)
      .to(this.textBlock_1, this.timeFrame, { top: '-100%' }, 'transition_2')
      .to(this.textBlock_2, this.timeFrame, { top: '0%' }, 'transition_2')
      
      .add('transition_3')
      .to(this.scene_1, this.timeFrame, { top: '-100%' }, 'transition_3')
      .to(this.scene_2, this.timeFrame, { top: '-100%' }, 'transition_3')
      .to(this.textBlock_2, this.timeFrame, { top: '-100%' }, 'transition_3')
      .to(this.textBlock_3, this.timeFrame, { top: '0%' }, 'transition_3')
      
      .add('transition_4')
      .to(this.pc, this.timeFrame, { left: '100%' }, 'transition_4')
      .to(this.men, this.timeFrame, { bottom: '0%' }, 'transition_4')
      .to(this.textBlock_3, this.timeFrame, { top: '-100%' }, 'transition_4')
      .to(this.textBlock_4, this.timeFrame, { top: '0%' }, 'transition_4')

      
      .add('transition_5')
      .to(this.men, this.timeFrame * 0.5, { left: '100%' }, 'transition_5')
      .to(this.table, this.timeFrame * 0.5, { bottom: '-50%' }, `transition_5+=${this.timeFrame * 0.5}`)
      .to(this.textStrip, this.timeFrame, { top: '-100%' }, 'transition_5')
  }

  render() {
    return (
      <AnimatablePage master={this.master} duration={this.timeFrame} freeze="" autoPlay>
        {({ handleWheel, handleTouchStart, handleTouchEnd, completed}) => {
          return (
            <Scenario onWheel={handleWheel.bind(this)} 
              onTouchEnd={handleTouchEnd.bind(this)}
              onTouchStart={handleTouchStart.bind(this)}>

              {/* SCENA 1 */}
              <Scene ref={div => div && (this.scene_1 = div)}>
                <Office />
                <BlankScreen ref={div => div && (this.blank_screen = div)} />
                <Charts ref={div => div && (this.charts = div)} />
                <Blur ref={div => div && (this.blur = div)}
                  style={{ zIndex: 1, opacity: 0 }} />
                <Women ref={div => div && (this.women = div)} />
              </Scene>

              {/* SCENA 2 */}
              <Scene ref={div => div && (this.scene_2 = div)}>
                <Room />
                <Table ref={div => div && (this.table = div)} />
                <PC ref={div => div && (this.pc = div)} />
                <Men ref={div => div && (this.men = div)} />
              </Scene>

              {/* TEXT */}
              <TextStrip ref={div => div && (this.textStrip = div)}>
                <TextBlock ref={div => div && (this.textBlock_1 = div)} style={{ top : '0%' }}>
                  <ScenarioTitle scenario="bad3">
                    <FormattedHTMLMessage id="bad3.text:1" />
                  </ScenarioTitle>
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_2 = div)}>
                  <FormattedHTMLMessage id="bad3.text:2" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_3 = div)}>
                  <FormattedHTMLMessage id="bad3.text:3" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_4 = div)}>
                  <FormattedHTMLMessage id="bad3.text:4" />
                </TextBlock>
              </TextStrip>

              {/* QUESTIONS */}
              <QuestionsController questions={[
                'scenario3.q1',
                'scenario3.q2',
                'scenario3.q3'
              ].map(id => ({ id, answers: 5 }))} active={completed()} />
            </Scenario>
          )
        }}
      </AnimatablePage>
    )
  }

}