import styled from 'styled-components';

export const Men = styled.div`
  position: absolute;
  height: 70%;
  bottom: -100%;
  width: 44%;
  left: 52%;
  background-image: url(/assets/images/bad3/men.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
`