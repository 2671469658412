import styled from 'styled-components';

export const CloudySky = styled.div`
  position: absolute;
  height: 100%;
  bottom: 0%;
  width: 100%;
  background-image: url('/assets/images/bad2/cloudy_sky.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
`