import React, { Fragment, useEffect } from 'react';
import { UserModelInstance } from "../models/UserModel";
import { observer } from 'mobx-react';
import { useStore } from '../models/GameStoreProvider';

type Props = {
  userID: string;
  children: React.FC<{ user: UserModelInstance }>;
};

const User: React.FC<Props> = (props) => {

  const store = useStore();
  const user = store.getUserByID(props.userID);

  useEffect(() => {
    store.loadUserByID(props.userID);
  })

  return (
    <Fragment>
      {user && props.children && props.children({ user })}
    </Fragment>
  )
}

export default observer(User);