import React, { Fragment, useEffect } from 'react';
import { GameModelInstance } from "../models/GameModel";
import { observer } from 'mobx-react';
import { useStore } from '../models/GameStoreProvider';

type Props = {
  gameId: string;
  children: React.SFC<{ game: GameModelInstance }>;
};

const Game: React.SFC<Props> = (props) => {

  const store = useStore();
  const game = store.getByID(props.gameId);

  useEffect(() => {
    store.loadByID(props.gameId);
  })

  return (
    <Fragment>
      {game && props.children && props.children({ game })}
    </Fragment>
  )
}

export default observer(Game);