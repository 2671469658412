import React, { useState } from 'react';
import styled from 'styled-components';
import { Send } from './Send';

const MessageInputBox = styled.div`
  background-color: #f0f0f0;
  height: 145px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextArea = styled.textarea`
  height: 80px;
  width: 70%;
  border: none;
  font-family: inherit;
  align-items: center;
  padding: 0.5em;
  border-radius: 15px;
  outline: none;
  resize: none;
`

const SendButton = styled.div<{ disabled?: boolean }>`
  height: 45px;
  width: 45px;
  background-color: ${props => props.disabled ? 'gray' : '#364281'};
  border-radius: 50%;
  margin-left: 1em;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.disabled ? '' : `
    &:hover {
      background-color: white;
      border: 1px solid #364281;
    }
    &:hover > svg {
      fill: #364281;
    }
  `}

  & > svg {
    fill: white;
  }
`

type MessageInputProps = {
  onSend: (text: string) => void
}
export const MessageInput: React.FC<MessageInputProps> = (props) => {
  const [text, onChange] = useState('');
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (text.trim() !== '' && e.key === "Enter" && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault()
      props.onSend(text.trim());
      onChange('');
    } else {
      e.stopPropagation()
    }
  }
  return (
    <MessageInputBox>
      <TextArea onKeyPress={handleKeyPress} value={text} onChange={e => onChange(e.target.value)} placeholder="Type your message..."></TextArea>
      <SendButton disabled={text === ''} onClick={() => {
        if (text.trim() !== '') {
          props.onSend(text.trim());
          onChange('');
        }
      }}>
        <Send />
      </SendButton>
    </MessageInputBox>
  )
}