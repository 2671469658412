import styled from 'styled-components';

export const Avatar = styled.div<{ own: boolean }>`
  background-color: ${props => props.color};
  height: 42px;
  width: 42px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  margin-${props => props.own ? 'left' : 'right'}: 1em;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    height: 60%;
  }
`