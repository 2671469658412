import styled from 'styled-components';

export const IntroductionBox = styled.div` 
  width: 40%;
  position: absolute;
  top: 120%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  left: 8%;
  padding: 4rem;
  padding-top: 0.5rem;
  z-index: 99;

  @media (max-height: 800px) and (max-width: 1439px) {
    & {
      padding: 0.5rem 2rem;
      line-height: 1.3em;
    }
  }
`