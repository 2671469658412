import styled from 'styled-components';

export const Roof = styled.div`
  position: absolute;
  height: 100%;
  bottom: -100%;
  width: 100%;
  background-image: url('/assets/images/bad2/roof.png');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
`