import React from "react";
import { FormattedHTMLMessage } from "react-intl";

import { Scenario } from "../Scenario";
import { Scene } from "../Scene";
import { AnimatablePage } from "../AnimatablePage";
import { QuestionsController } from "../QuestionsController";
import { TextStrip } from "../TextStrip";
import { TextBlock } from "../TextBlock";
import { ScenarioTitle } from "../ScenarioTitle";

import { Wall } from "../scenario2/Wall";
import { Rocket } from "../scenario2/Rocket";
import { RocketSteam } from "../scenario2/RocketSteam";
import { Woman } from "../scenario2/Woman";
import { Billboard } from "../scenario2/Billboard";
import { WomanYellow } from "./elements/WomanYellow";
import { Roof } from "./elements/Roof";
import { Wall2 } from "./elements/Wall2";
import { Arrow } from "./elements/Arrow";
import { CloudySky } from "./elements/CloudySky";
import { Man } from "./elements/Man";
import { ComingSoon } from "./elements/ComingSoon";
import SceneClass from "../SceneClass";

export default class Bad2 extends SceneClass {
  scene_1: HTMLDivElement | {} = {};
  wall: HTMLDivElement | {} = {};
  rocket: HTMLDivElement | {} = {};
  rocket_steam: HTMLDivElement | {} = {};
  woman: HTMLDivElement | {} = {};
  woman_yellow: HTMLDivElement | {} = {};

  scene_2: HTMLDivElement | {} = {};
  arrow: HTMLDivElement | {} = {};
  coming_soon: HTMLDivElement | {} = {};

  scene_3: HTMLDivElement | {} = {};
  roof: HTMLDivElement | {} = {};
  man: HTMLDivElement | {} = {};
  billboard: HTMLDivElement | {} = {};

  textStrip: HTMLDivElement | {} = {};
  textBlock_1: HTMLDivElement | {} = {};
  textBlock_2: HTMLDivElement | {} = {};
  textBlock_3: HTMLDivElement | {} = {};
  textBlock_4: HTMLDivElement | {} = {};
  textBlock_5: HTMLDivElement | {} = {};
  textBlock_6: HTMLDivElement | {} = {};
  textBlock_7: HTMLDivElement | {} = {};
  textBlock_8: HTMLDivElement | {} = {};

  componentDidMount() {
    this.master
      .add('transition_1')
      .to(this.rocket, this.timeFrame, { bottom: '7%' }, 'transition_1')
      .to(this.textStrip, this.timeFrame, { top: '0px' }, 'transition_1')
      .to(this.woman, this.timeFrame, { opacity: 1 }, 'transition_1')
      
      .add('transition_3')
      .to(this.rocket_steam, this.timeFrame * 0.25, { opacity: 1 }, `transition_3`)
      .to(this.rocket_steam, this.timeFrame * 0.75, { top: '-100%' }, `transition_3+=${this.timeFrame * 0.25}`)
      .to(this.rocket, this.timeFrame * 0.75, { top: '-100%' }, `transition_3+=${this.timeFrame * 0.25}`)
      .to(this.textBlock_1, this.timeFrame, { top: '-100%' }, 'transition_3')
      .to(this.textBlock_2, this.timeFrame, { top: '0%' }, 'transition_3')
      
      .add('transition_4')
      .to(this.woman, this.timeFrame * 0.5, { left: '100%' }, 'transition_4')
      .to(this.woman_yellow, this.timeFrame * 0.5, { bottom: '0' }, `transition_4+=${this.timeFrame * 0.5}`)
      .to(this.textBlock_2, this.timeFrame, { top: '-100%' }, 'transition_4')
      .to(this.textBlock_3, this.timeFrame, { top: '0%' }, 'transition_4')

      
      .add('transition_5')
      .to(this.scene_1, this.timeFrame, { top: '-100%' }, 'transition_5')
      .to(this.scene_2, this.timeFrame, { top: '-100%' }, 'transition_5')
      .to(this.scene_3, this.timeFrame, { top: '-100%' }, 'transition_5')
      .to(this.textBlock_3, this.timeFrame, { top: '-100%' }, 'transition_5')
      .to(this.textBlock_4, this.timeFrame, { top: '0%' }, 'transition_5')
      
      .add('transition_6')
      .to(this.arrow, this.timeFrame, { opacity: 1 }, 'transition_6')
      .to(this.coming_soon, this.timeFrame, { opacity: 1 }, 'transition_6')
      .to(this.textBlock_4, this.timeFrame, { top: '-100%' }, 'transition_6')
      .to(this.textBlock_5, this.timeFrame, { top: '0%' }, 'transition_6')

      
      .add('transition_7')
      .to(this.scene_2, this.timeFrame, { top: '-200%' }, 'transition_7')
      .to(this.scene_3, this.timeFrame, { top: '-200%' }, 'transition_7')
      .to(this.textBlock_5, this.timeFrame, { top: '-100%' }, 'transition_7')
      .to(this.textBlock_6, this.timeFrame, { top: '0%' }, 'transition_7')

      .add('transition_8')
      .to(this.roof, this.timeFrame * 0.5, { bottom: 0 }, 'transition_8')
      .to(this.man, this.timeFrame * 0.5, { opacity: 1 }, `transition_8+=${this.timeFrame * 0.5}`)
      .to(this.textBlock_6, this.timeFrame, { top: '-100%' }, 'transition_8')
      .to(this.textBlock_7, this.timeFrame, { top: '0%' }, 'transition_8')

      .add('transition_9')
      .to(this.man, this.timeFrame * 0.5, { opacity: 0 }, 'transition_9')
      .to(this.billboard, this.timeFrame * 0.5, { left: '55%' }, `transition_9+=${this.timeFrame * 0.5}`)
      .to(this.textBlock_7, this.timeFrame, { top: '-100%' }, 'transition_9')
      .to(this.textBlock_8, this.timeFrame, { top: '0%' }, 'transition_9')
            
      .add('transition_10')
      .to(this.billboard, this.timeFrame, { left: '100%' }, 'transition_10')
      .to(this.textBlock_8, this.timeFrame, { top: '-100%' }, 'transition_10')
      .to(this.textStrip, this.timeFrame, { top: '-100%' }, 'transition_10')
  }

  render() {
    return (
      <AnimatablePage master={this.master} duration={this.timeFrame} freeze="" autoPlay>
        {({ handleWheel, handleClick, handleTouchStart, handleTouchEnd, completed}) => {
          if (this.master.progress() === 0) {
            handleClick();
          }
          return (
            <Scenario onWheel={handleWheel.bind(this)} 
              onTouchEnd={handleTouchEnd.bind(this)}
              onTouchStart={handleTouchStart.bind(this)}>
              {/* SCENA 1 */}
              <Scene ref={div => div && (this.scene_1 = div)}>
                <Wall ref={div => div && (this.wall = div)} />
                <RocketSteam ref={div => div && (this.rocket_steam = div)} />
                <Rocket ref={div => div && (this.rocket = div)} />
                <Woman ref={div => div && (this.woman = div)} />
                <WomanYellow ref={div => div && (this.woman_yellow = div)} />
              </Scene>

              {/* SCENA 2 */}
              <Scene ref={div => div && (this.scene_2 = div)}>
                <Wall2 />
                <Arrow ref={div => div && (this.arrow = div)} />
                <ComingSoon ref={div => div && (this.coming_soon = div)}>
                  <FormattedHTMLMessage id="coming.soon" />
                </ComingSoon>
              </Scene>

              {/* SCENA 3 */}
              <Scene ref={div => div && (this.scene_3 = div)}>
                <CloudySky />
                <Billboard ref={div => div && (this.billboard = div)} />
                <Roof ref={div => div && (this.roof = div)} />
                <Man ref={div => div && (this.man = div)} />
              </Scene>

              {/* TEXT */}
              <TextStrip ref={div => div && (this.textStrip = div)}>
                <TextBlock ref={div => div && (this.textBlock_1 = div)} style={{ top: '0%' }}>
                  <ScenarioTitle scenario="bad2">
                    <FormattedHTMLMessage id="bad2.text:1" />
                  </ScenarioTitle>
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_2 = div)}>
                  <FormattedHTMLMessage id="bad2.text:2" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_3 = div)}>
                  <FormattedHTMLMessage id="bad2.text:3" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_4 = div)}>
                  <FormattedHTMLMessage id="bad2.text:4" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_5 = div)}>
                  <FormattedHTMLMessage id="bad2.text:5" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_6 = div)}>
                  <FormattedHTMLMessage id="bad2.text:6" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_7 = div)}>
                  <FormattedHTMLMessage id="bad2.text:7" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_8 = div)}>
                  <FormattedHTMLMessage id="bad2.text:8" />
                </TextBlock>
              </TextStrip>

              {/* QUESTIONS */}
              <QuestionsController questions={[
                'scenario2.q1',
                'scenario2.q2',
                'scenario2.q3'
              ].map(id => ({ id, answers: 5 }))} active={completed()} />
            </Scenario>
          )
        }}
      </AnimatablePage>
    )
  }

}