import styled from 'styled-components';

export const Background = styled.div`
  position: absolute;
  height: 100%;
  top: 0%;
  width: 100%;
  background-position: right center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
`