import React from "react";
import { Linear } from "gsap";
import { FormattedHTMLMessage } from "react-intl";

import './index.css';
import { Scenario } from "../Scenario";
import { AnimatablePage } from "../AnimatablePage";
import { QuestionsController } from "../QuestionsController";
import { SkyBackground } from "../SkyBackground";
import { Skyline } from "../Skyline";

import { ReadyGo } from "./elements/ReadyGo";
import { Airplane } from "./elements/Airplane";
import { IntroductionBox } from "./elements/IntroductionBox";
import { IntroductionTextBox } from "./elements/IntroductionTextBox";
import { Skyscraper } from "./elements/Skyscraper";
import { Airplane2 } from "./elements/Airplane2";
import { Roof } from "./elements/Roof";
import { MeetingRoom } from "./elements/MeetingRoom";
import { MeetingTable } from "./elements/MeetingTable";
import { IPad } from "./elements/IPad";
import { IntroductionTextBlock } from "./elements/IntroductionTextBlock";
import { TextKind2 } from "./elements/TextKind2";
import { IntroductionTextBlock2 } from "./elements/IntroductionTextBlock2";
import { TextKind3 } from "./elements/TextKind3";
import SceneClass from "../SceneClass";
import Game from "../Game";

export default class Intro extends SceneClass<{ gameId: string }> {
  skyBackground: HTMLDivElement | {} = {}
  readyGo: HTMLDivElement | {} = {}
  airplane: HTMLDivElement | {} = {}
  introductionBox: HTMLDivElement | {} = {}
  skyscraper1: HTMLDivElement | {} = {}
  skyscraper2: HTMLDivElement | {} = {}
  skyscraper3: HTMLDivElement | {} = {}
  skyscraper4: HTMLDivElement | {} = {}
  skyscraper5: HTMLDivElement | {} = {}
  skyscraper6: HTMLDivElement | {} = {}
  airplane2: HTMLDivElement | {} = {}

  introBlock_1: HTMLDivElement | {} = {}
  introBlock_2: HTMLDivElement | {} = {}

  introBlock_1_1_text: HTMLDivElement | {} = {}
  
  introBlock_1_3_text: HTMLDivElement | {} = {}
  introBlock_1_4_text: HTMLDivElement | {} = {}
  introBlock_1_5_text: HTMLDivElement | {} = {}

  roof: HTMLDivElement | {} = {}
  introBlock_1_6_text: HTMLDivElement | {} = {}

  skyline: HTMLDivElement | {} = {}
  introBlock_1_7_text: HTMLDivElement | {} = {}
  
  meeting_room: HTMLDivElement | {} = {}
  introBlock_1_8_text: HTMLDivElement | {} = {}

  meeting_table: HTMLDivElement | {} = {}
  introBlock_1_9_text: HTMLDivElement | {} = {}

  ipad: HTMLDivElement | {} = {}
  introBlock_1_10_text: HTMLDivElement | {} = {}
  introBlock_1_11_text: HTMLDivElement | {} = {}
  introBlock_1_12_text: HTMLDivElement | {} = {}

  componentDidMount() {
    this.master      
      .add('scena_3')
      .to(this.readyGo, this.timeFrame * 0.5, { top: '-100%' }, `scena_3+=${this.timeFrame * 0.35}`)
      .to(this.skyBackground, this.timeFrame * 0.85, { backgroundPosition: '100% 100%', ease: Linear.easeNone }, `scena_3+=${this.timeFrame * 0.15}`)
      .to(this.airplane, this.timeFrame, { bottom: '100vh' }, 'scena_3')
      .to(this.introductionBox, this.timeFrame*0.6, { top: '0px' }, `scena_3+=${this.timeFrame * 0.4}`)
      .to(this.introBlock_1_1_text, this.timeFrame * 0.6, { top: '0%' }, `scena_3+=${this.timeFrame * 0.4}`)
      .to(this.skyscraper1, this.timeFrame, { bottom: '0px', left: 0 }, 'scena_3')
      .to(this.skyscraper2, this.timeFrame, { top: '0px', right: 0 }, 'scena_3')
      
      .add('scena_4')
      .to(this.introBlock_1_1_text, this.timeFrame * 0.5, { top: '-100%' }, 'scena_4')
      .to(this.introBlock_1_3_text, this.timeFrame * 0.5, { top: '0%' }, `scena_4+=${this.timeFrame * 0.5}`)
      .to(this.skyscraper3, this.timeFrame, { bottom: '0px' }, 'scena_4')
      .to(this.skyscraper4, this.timeFrame, { right: '0px', bottom: '0px' }, 'scena_4')
      .to(this.airplane2, this.timeFrame, { left: '0px', top: '40%' }, 'scena_4')
   
      .add('scena_5')
      .to(this.introBlock_1_4_text, this.timeFrame, { top: '0%' }, `scena_5`)
      .to(this.skyscraper5, this.timeFrame, { top: '0px', left: '0px' }, 'scena_5')
      .to(this.skyscraper6, this.timeFrame, { top: '0px', }, 'scena_5')
      .to(this.airplane2, this.timeFrame, { left: '110%', top: '55%' }, 'scena_5')
   
      .add('scena_6')
      .to(this.introBlock_1_5_text, this.timeFrame, { top: '0%' }, `scena_8`)

      .add('scena_9')
      .to(this.introBlock_1, this.timeFrame*0.5, { top: '-100%' }, 'scena_9')
      .to(this.skyscraper1, this.timeFrame*0.5, { left: '-20%', bottom: '-30%' }, 'scena_9')
      .to(this.skyscraper2, this.timeFrame*0.5, { right: '-20%', top: '0px' }, 'scena_9')
      .to(this.skyscraper3, this.timeFrame*0.5, { left: '20%', bottom: '-30%' }, 'scena_9')
      .to(this.skyscraper4, this.timeFrame*0.5, { right: '-20%', bottom: '-30%' }, 'scena_9')
      .to(this.skyscraper5, this.timeFrame*0.5, { left: '-20%', top: '-30%' }, 'scena_9')
      .to(this.skyscraper6, this.timeFrame*0.5, { left: '20%', top: '-30%' }, 'scena_9')
      .to(this.introBlock_2, this.timeFrame*0.5, { top: '-100%' }, `scena_9+=${this.timeFrame*0.5}`)
      .to(this.roof, this.timeFrame*0.5, { bottom: '0%' }, `scena_9+=${this.timeFrame*0.5}`)
      .to(this.introBlock_1_6_text, this.timeFrame * 0.5, { top: '0%' }, `scena_9+=${this.timeFrame * 0.5}`)

      .add('scena_10')
      .to(this.introBlock_1_6_text, this.timeFrame * 0.4, { top: '-100%' }, `scena_10`)
      .to(this.introBlock_1_7_text, this.timeFrame * 0.4, { top: '-100%' }, `scena_10+=${this.timeFrame * 0.4}`)
      .to(this.skyline, this.timeFrame * 0.6, { bottom: '0%' }, `scena_10+=${this.timeFrame * 0.4}`)
      
      .add('scena_11')
      .to(this.introBlock_1_7_text, this.timeFrame, { top: '-200%' }, `scena_11`)
      .to(this.introBlock_1_9_text, this.timeFrame, { top: '-200%' }, `scena_11`)
      .to(this.roof, this.timeFrame, { bottom: '100%', display: 'none' }, `scena_11`)
      .to(this.skyline, this.timeFrame, { bottom: '100%' }, `scena_11`)
      .to(this.meeting_room, this.timeFrame, { bottom: '0%' }, `scena_11`)

      .add('scena_12')
      .to(this.introBlock_1_9_text, this.timeFrame, { top: '-300%' }, `scena_12`)
      .to(this.introBlock_1_10_text, this.timeFrame, { top: '-300%' }, `scena_12`)
      .to(this.meeting_table, this.timeFrame, { bottom: '0%' }, `scena_12`)
      
      .add('scena_13')
      .fromTo(this.skyline, this.timeFrame, { bottom: '-100%' }, { bottom: '0%' }, `scena_13`)
      .to(this.introBlock_1_10_text, this.timeFrame, { top: '-400%' }, `scena_13`)
      .to(this.introBlock_1_11_text, this.timeFrame, { top: '-400%' }, `scena_13`)
      
      .add('scena_13_bis')
      .to(this.introBlock_1_11_text, this.timeFrame, { top: '-500%' }, `scena_13_bis`)
      .to(this.introBlock_1_12_text, this.timeFrame, { top: '-500%' }, `scena_13_bis`)
      .to(this.ipad, this.timeFrame, { right: '0%' }, `scena_13_bis`)

      .add('scena_14')
      .to(this.introBlock_1_12_text, this.timeFrame*0.7, { top: '-600%' }, `scena_14`)
      .to(this.ipad, this.timeFrame*0.7, { right: '-50%' }, `scena_14`)
      .to(this.introductionBox, this.timeFrame*0.7, { top: '-100%' }, 'scena_14')
  }

  render() {
    return (
      <AnimatablePage master={this.master} duration={this.timeFrame} freeze="">
        {({ handleWheel, handleTouchStart, handleTouchEnd, handleClick, completed }) => (
          <Game gameId={this.props.gameId}>
            {({ game }) => (
              <Scenario onWheel={handleWheel} 
                onTouchEnd={handleTouchEnd}
                onTouchStart={handleTouchStart}>
                {/* SCENA 1 e setup SCENA 2 */}
                <SkyBackground ref={div => div && (this.skyBackground = div)}>
                  <ReadyGo ref={div => div && (this.readyGo = div)} onClick={handleClick} />
                  <Airplane ref={div => div && (this.airplane = div)} />
                </SkyBackground>

                {/* SCENA 3 */}
                <IntroductionBox ref={div => div && (this.introductionBox = div)}>
                  <IntroductionTextBlock style={{ top: '0%' }} ref={div => div && (this.introBlock_1 = div)}>
                    <IntroductionTextBox>
                      <IntroductionTextBlock2>
                        <TextKind2 ref={div => div && (this.introBlock_1_1_text = div)}>
                          <b style={{ textTransform: 'uppercase' }}><FormattedHTMLMessage id="intro.title" /></b>
                          <br />
                          <br />
                          <FormattedHTMLMessage tagName="div" id="intro.text.1" />
                          <br />
                          <br />
                          <FormattedHTMLMessage tagName="div" id="intro.text.2" />
                        </TextKind2>
                      </IntroductionTextBlock2>
                      <IntroductionTextBlock2>
                        <TextKind2 ref={div => div && (this.introBlock_1_3_text = div)}>
                          <FormattedHTMLMessage id="intro.text.3" />
                        </TextKind2>
                        <TextKind2 ref={div => div && (this.introBlock_1_4_text = div)}>
                          <FormattedHTMLMessage id="intro.text.4" />
                        </TextKind2>
                        <TextKind2 ref={div => div && (this.introBlock_1_5_text = div)}>
                          <FormattedHTMLMessage id="intro.text.5" />
                        </TextKind2>
                      </IntroductionTextBlock2>
                    </IntroductionTextBox>
                  </IntroductionTextBlock>
                  <IntroductionTextBlock style={{ top: '100%' }}
                    ref={div => div && (this.introBlock_2 = div)}>
                    <TextKind3 ref={div => div && (this.introBlock_1_6_text = div)}>
                      <FormattedHTMLMessage id="intro.text.6" />
                    </TextKind3>
                    <TextKind3 ref={div => div && (this.introBlock_1_7_text = div)}>
                      <FormattedHTMLMessage id="intro.text.7" />
                      <br/>
                      <FormattedHTMLMessage id="intro.text.8" />
                    </TextKind3>
                    <TextKind3 ref={div => div && (this.introBlock_1_9_text = div)}>
                      <FormattedHTMLMessage id="intro.text.9" />
                    </TextKind3>
                    <TextKind3 ref={div => div && (this.introBlock_1_10_text = div)}>
                      <FormattedHTMLMessage id="intro.text.10" />
                    </TextKind3>
                    <TextKind3 ref={div => div && (this.introBlock_1_11_text = div)}>
                      <FormattedHTMLMessage id="intro.text.11" />
                    </TextKind3>
                    <TextKind3 ref={div => div && (this.introBlock_1_12_text = div)}>
                      <FormattedHTMLMessage id="intro.text.12" />
                    </TextKind3>
                  </IntroductionTextBlock>
                </IntroductionBox>
                <Skyscraper ref={div => div && (this.skyscraper1 = div)}
                  style={{ left: '-20%', bottom: '-30%', backgroundImage: 'url(/assets/images/intro/skyscraper_1.png)', backgroundPosition: 'top left' }} />
                <Skyscraper ref={div => div && (this.skyscraper2 = div)}
                  style={{ right: '-20%', top: '0px', backgroundImage: 'url(/assets/images/intro/skyscraper_2.png)', backgroundPosition: 'bottom left' }} />

                {/* SCENA 4 */}
                <Skyscraper ref={div => div && (this.skyscraper3 = div)}
                  style={{ left: '20%', bottom: '-30%', width: '60%', backgroundImage: 'url(/assets/images/intro/skyscraper_3.png)', backgroundPosition: 'top center' }} />
                <Skyscraper ref={div => div && (this.skyscraper4 = div)}
                  style={{ right: '-20%', bottom: '-30%', backgroundImage: 'url(/assets/images/intro/skyscraper_4.png)', backgroundPosition: 'top left' }} />
                <Airplane2 ref={div => div && (this.airplane2 = div)} />

                {/* SCENA 5 */}
                <Skyscraper ref={div => div && (this.skyscraper5 = div)}
                  style={{ left: '-20%', top: '-30%', backgroundImage: 'url(/assets/images/intro/skyscraper_5.png)', backgroundPosition: 'bottom right' }} />
                <Skyscraper ref={div => div && (this.skyscraper6 = div)}
                  style={{ left: '20%', top: '-30%', width: '60%', backgroundImage: 'url(/assets/images/intro/skyscraper_6.png)', backgroundPosition: 'bottom center' }} />

                {/* SCENA 9 */}
                <Roof ref={div => div && (this.roof = div)} />

                {/* SCENA 10 */}
                <Skyline ref={div => div && (this.skyline = div)} />

                {/* SCENA 11 */}
                <MeetingRoom ref={div => div && (this.meeting_room = div)} />

                {/* SCENA 12 */}
                <MeetingTable ref={div => div && (this.meeting_table = div)} />

                {/* SCENA 13 */}
                <IPad ref={div => div && (this.ipad = div)} />

                {/* QUESTIONS */}
                <QuestionsController questions={[
                  'intro.q1',
                  'intro.q2',
                  'intro.q3',
                ].map(id => ({ id, answers: 5 }))} active={completed()} />
              </Scenario>
            )}
          </Game>
        )}
      </AnimatablePage>
    )
  }

}