import styled from 'styled-components';

export const TextBlock = styled.div` 
  position: absolute;
  top: 100vh;
  left: 0;
  padding: 4rem;
  padding-top: 0.5rem;
  font-weight: 300;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  & div {
    width: 100%;
  }

  @media (max-height: 800px) and (max-width: 1439px) {
    & {
      padding: 0.5rem 2rem;
    }
  }
`