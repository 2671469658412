import React from "react";
import { FormattedHTMLMessage } from "react-intl";

import { Scenario } from "../Scenario";
import { SkyBackground } from "../SkyBackground";
import { Skyline } from "../Skyline";
import { Roof } from "./elements/Roof";
import { ManBack } from "./elements/ManBack";
import { ManFront } from "./elements/ManFront";
import { Money1 } from "./elements/Money1";
import { Money2 } from "./elements/Money2";
import { Money3 } from "./elements/Money3";
import { Money4 } from "./elements/Money4";
import { Floor } from "./elements/Floor";
import { Desk } from "./elements/Desk";
import { Table } from "./elements/Table";
import { PC } from "./elements/PC";
import { Scene } from "../Scene";
import { PersonOnDesk } from "./elements/PersonOnDesk";
import { AnimatablePage } from "../AnimatablePage";
import { QuestionsController } from "../QuestionsController";
import { TextStrip } from "../TextStrip";
import { TextBlock } from "../TextBlock";
import { ScenarioTitle } from "../ScenarioTitle";
import SceneClass from "../SceneClass";

export default class Good1 extends SceneClass {
  scene_1: HTMLDivElement | {} = {};
  man_back: HTMLDivElement | {} = {};
  man_front: HTMLDivElement | {} = {};
  money_1: HTMLDivElement | {} = {};
  money_2: HTMLDivElement | {} = {};
  money_3: HTMLDivElement | {} = {};
  money_4: HTMLDivElement | {} = {};

  scene_2: HTMLDivElement | {} = {};
  desk: HTMLDivElement | {} = {};
  top_left_desk: HTMLDivElement | {} = {};
  middle_left_desk: HTMLDivElement | {} = {};
  bottom_left_desk: HTMLDivElement | {} = {};
  top_right_desk: HTMLDivElement | {} = {};
  middle_right_desk: HTMLDivElement | {} = {};
  bottom_right_desk: HTMLDivElement | {} = {};
  table: HTMLDivElement | {} = {};
  pc: HTMLDivElement | {} = {};

  textStrip: HTMLDivElement | {} = {};
  textBlock_1: HTMLDivElement | {} = {};
  textBlock_2: HTMLDivElement | {} = {};
  textBlock_3: HTMLDivElement | {} = {};
  textBlock_4: HTMLDivElement | {} = {};
  textBlock_5: HTMLDivElement | {} = {};
  textBlock_6: HTMLDivElement | {} = {};

  componentDidMount() {
    this.master
      .add('transition_1')
      .to(this.man_back, this.timeFrame, { opacity: 1 }, 'transition_1')
      .to(this.textStrip, this.timeFrame, { top: '0px' }, 'transition_1')

      .add('transition_2')
      .to(this.man_front, this.timeFrame, { opacity: 1 }, 'transition_2')
      .to(this.textBlock_1, this.timeFrame, { top: '-100%' }, 'transition_2')
      .to(this.textBlock_2, this.timeFrame, { top: '0%' }, 'transition_2')

      .add('transition_3')
      .to(this.money_1, this.timeFrame * 0.25, { opacity: 1 }, `transition_3`)
      .to(this.money_2, this.timeFrame * 0.25, { opacity: 1 }, `transition_3+=${this.timeFrame * 0.25}`)
      .to(this.money_3, this.timeFrame * 0.25, { opacity: 1 }, `transition_3+=${this.timeFrame * 0.75}`)
      .to(this.money_4, this.timeFrame * 0.25, { opacity: 1 }, `transition_3+=${this.timeFrame * 0.5}`)
      .to(this.textBlock_2, this.timeFrame, { top: '-100%' }, 'transition_3')
      .to(this.textBlock_3, this.timeFrame, { top: '0%' }, 'transition_3')

      .add('transition_4')
      .to(this.scene_1, this.timeFrame, { top: '-100%' }, 'transition_4')
      .to(this.scene_2, this.timeFrame, { top: '-100%' }, 'transition_4')
      .to(this.textBlock_3, this.timeFrame, { top: '-100%' }, 'transition_4')
      .to(this.textBlock_4, this.timeFrame, { top: '0%' }, 'transition_4')

      .add('transition_5')
      .to(this.middle_right_desk, this.timeFrame * 0.25, { opacity: 1 }, `transition_5`)
      .to(this.top_left_desk, this.timeFrame * 0.25, { opacity: 1 }, `transition_5+=${this.timeFrame * 0.15}`)
      .to(this.middle_left_desk, this.timeFrame * 0.25, { opacity: 1 }, `transition_5+=${this.timeFrame * 0.15 * 2}`)
      .to(this.top_right_desk, this.timeFrame * 0.25, { opacity: 1 }, `transition_5+=${this.timeFrame * 0.15 * 3}`)
      .to(this.bottom_left_desk, this.timeFrame * 0.25, { opacity: 1 }, `transition_5+=${this.timeFrame * 0.15 * 4}`)
      .to(this.bottom_right_desk, this.timeFrame * 0.25, { opacity: 1 }, `transition_5+=${this.timeFrame * 0.15 * 5}`)
      .to(this.textBlock_4, this.timeFrame, { top: '-100%' }, 'transition_5')
      .to(this.textBlock_5, this.timeFrame, { top: '0%' }, 'transition_5')

      .add('transition_6')
      .to(this.table, this.timeFrame * 0.5, { bottom: '0%' }, 'transition_6')
      .to(this.desk, this.timeFrame * 0.5, { bottom: '100%' }, 'transition_6')
      .to(this.middle_right_desk, this.timeFrame * 0.5, { bottom: '100%' }, 'transition_6')
      .to(this.top_left_desk, this.timeFrame * 0.5, { bottom: '100%' }, 'transition_6')
      .to(this.middle_left_desk, this.timeFrame * 0.5, { bottom: '100%' }, 'transition_6')
      .to(this.top_right_desk, this.timeFrame * 0.5, { bottom: '100%' }, 'transition_6')
      .to(this.bottom_left_desk, this.timeFrame * 0.5, { bottom: '100%' }, 'transition_6')
      .to(this.bottom_right_desk, this.timeFrame * 0.5, { bottom: '100%' }, 'transition_6')
      .to(this.pc, this.timeFrame * 0.5, { left: '50%' }, `transition_6+=${this.timeFrame * 0.5}`)
      .to(this.textBlock_5, this.timeFrame, { top: '-100%' }, 'transition_6')
      .to(this.textBlock_6, this.timeFrame, { top: '0%' }, 'transition_6')

      .add('transition_7')
      .to(this.table, this.timeFrame, { bottom: '-100%' }, 'transition_7')
      .to(this.pc, this.timeFrame * 0.5, { left: '100%' }, `transition_7`)
      .to(this.textBlock_6, this.timeFrame, { top: '-100%' }, 'transition_7')
      .to(this.textStrip, this.timeFrame, { top: '-100%' }, 'transition_7')
  }

  render() {
    return (
      <AnimatablePage master={this.master} duration={this.timeFrame} freeze="" autoPlay>
        {({ handleWheel, handleTouchStart, handleTouchEnd, completed}) => {
          return (
            <Scenario onWheel={handleWheel.bind(this)} 
              onTouchEnd={handleTouchEnd.bind(this)}
              onTouchStart={handleTouchStart.bind(this)}>
              {/* SCENA 1 */}
              <Scene ref={div => div && (this.scene_1 = div)}>
                <SkyBackground />
                <ManFront ref={div => div && (this.man_front = div)} />
                <Skyline style={{ bottom: 0 }} />
                <Roof />
                <Money1 ref={div => div && (this.money_1 = div)} />
                <Money2 ref={div => div && (this.money_2 = div)} />
                <Money3 ref={div => div && (this.money_3 = div)} />
                <Money4 ref={div => div && (this.money_4 = div)} />
                <ManBack ref={div => div && (this.man_back = div)} />
              </Scene>

              {/* SCENA 2 */}
              <Scene ref={div => div && (this.scene_2 = div)}>
                <Floor />
                <Desk ref={div => div && (this.desk = div)} />
                <PersonOnDesk ref={div => div && (this.top_left_desk = div)}
                  style={{ backgroundImage: 'url(/assets/images/good1/top_left_desk.png)' }} />
                <PersonOnDesk ref={div => div && (this.middle_left_desk = div)}
                  style={{ backgroundImage: 'url(/assets/images/good1/middle_left_desk.png)' }} />
                <PersonOnDesk ref={div => div && (this.bottom_left_desk = div)}
                  style={{ backgroundImage: 'url(/assets/images/good1/bottom_left_desk.png)' }} />
                <PersonOnDesk ref={div => div && (this.top_right_desk = div)}
                  style={{ backgroundImage: 'url(/assets/images/good1/top_right_desk.png)' }} />
                <PersonOnDesk ref={div => div && (this.middle_right_desk = div)}
                  style={{ backgroundImage: 'url(/assets/images/good1/middle_right_desk.png)' }} />
                <PersonOnDesk ref={div => div && (this.bottom_right_desk = div)}
                  style={{ backgroundImage: 'url(/assets/images/good1/bottom_right_desk.png)' }} />
                <Table ref={div => div && (this.table = div)} />
                <PC ref={div => div && (this.pc = div)} />
              </Scene>

              {/* TEXT */}
              <TextStrip ref={div => div && (this.textStrip = div)}>
                <TextBlock ref={div => div && (this.textBlock_1 = div)} style={{ top: '0%' }}>
                  <ScenarioTitle scenario="good1">
                    <FormattedHTMLMessage id="good1.text:1" />
                  </ScenarioTitle>
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_2 = div)}>
                  <FormattedHTMLMessage id="good1.text:2" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_3 = div)}>
                  <FormattedHTMLMessage id="good1.text:3" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_4 = div)}>
                  <FormattedHTMLMessage id="good1.text:4" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_5 = div)}>
                  <FormattedHTMLMessage id="good1.text:5" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_6 = div)}>
                  <FormattedHTMLMessage id="good1.text:6" />
                </TextBlock>
              </TextStrip>

              {/* QUESTIONS */}
              <QuestionsController questions={[
                'scenario1.q1',
                'scenario1.q2',
                'scenario1.q3'
              ].map(id => ({ id, answers: 5 }))} active={completed()} />
            </Scenario>
          )
        }}
      </AnimatablePage>
    )
  }

}