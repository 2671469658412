import styled from 'styled-components';

export const Porthole = styled.div`
  position: fixed;
  top: 100px;
  bottom: 0px;
  width: 100%;
  background-image: url('/assets/images/login/oblo_interno.png');
  background-position: center 70%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 103;
  cursor: pointer;

  &:hover {
    background-image: url('/assets/images/login/oblo_interno_hov.png');
  }
`