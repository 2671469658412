import styled from 'styled-components';

export const AlarmBW = styled.div`
  position: absolute;
  height: 100%;
  bottom: 0%;
  width: 100%;
  background-image: url('/assets/images/gym/alarm_bw.jpg');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
`