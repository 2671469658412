import styled from 'styled-components';

export const AnswerNumber = styled.div`
  top: -48px;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 48px;
  color: #143366;
  font-size: 58px;
  font-weight: 900;
  line-height: 58px;
  text-align: center;
`