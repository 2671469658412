import React from 'react';
import styled from 'styled-components';
import { FormattedHTMLMessage } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars';

const ScenarioDetails = styled.div`
  position: absolute;
  left: 0%;
  width: 100%;
  background-color: white;
  border-right: 2px solid black;
  z-index: -1;
  height: 100%;
  top: 0;

  transition: left 1s;
`

const ScenarioBox = styled.div<{ active: boolean }>`
  ${
    props => props.active
      ? `
        ${ScenarioTitle} {
          background-color: rgba(0, 70, 169, 0.1);
          font-weight: bold;
        }
      `
      : ``
  }
`

const ScenarioTitle = styled.div`
  padding: 2em;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > span {
    margin-left: 1em;
  }

  &:hover {
    background-color: rgba(0, 70, 169, 0.1);
  }
`

const ScenarioContent = styled.div`
  padding: 2em;
  font-size: 0.75em;
`

const ScenarioQuestion = styled.div`
  margin: 2em 0;
`

const Close = styled.div`
  text-align: right;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
`

type Question = {
  questionID: string
  value: number
}

type ScenarioProps = {
  id: string
  questions: Question[]
  active: boolean
  onSelect: () => void
  onClose: () => void
}

export const Scenario: React.FC<ScenarioProps> = (props) => { 
  return (
    <ScenarioBox active={props.active}>
      <ScenarioTitle onClick={props.onSelect}>
        ⇽ <FormattedHTMLMessage id={`${props.id}.title`} />
      </ScenarioTitle>
      <ScenarioDetails style={{ left: props.active ? '-100%' : '0' }}>
        <Scrollbars>
          <ScenarioContent>
            <Close onClick={props.onClose}>⇾</Close>
            <FormattedHTMLMessage tagName="div" id={`${props.id}.history.fullText`} />
            <hr />
            <b style={{ color: '#fe9701', textTransform: 'uppercase'}}><FormattedHTMLMessage tagName="div" id={`given-answers`} /></b>
            {
              props.questions.map((question, index) => (
                <ScenarioQuestion key={index}>
                  <b><FormattedHTMLMessage tagName="div" id={`${question.questionID}.statement`} /></b>
                  <FormattedHTMLMessage tagName="div" id={`${question.questionID}.answer.${question.value}`} />
                </ScenarioQuestion>
              ))
            }
          </ScenarioContent>
          
        </Scrollbars>
        
      </ScenarioDetails>
    </ScenarioBox>
  )
}