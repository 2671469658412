import styled from 'styled-components';

export const Ipad = styled.div`
  position: absolute;
  height: 80%;
  bottom: 0%;
  width: 50%;
  left: 100%;
  background-image: url(/assets/images/bad1/ipad.png);
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
`