import styled from 'styled-components';

export const TextKind3 = styled.div` 
  position: relative;
  top: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  letter-spacing: 0.86px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 300;
`;