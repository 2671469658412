import styled from 'styled-components';

export const Blur = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1;
  backdrop-filter: blur(5px);
`