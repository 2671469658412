import styled from 'styled-components';

export const NotReadBadge = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fe9701;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 12px;
`