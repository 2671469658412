import styled from 'styled-components';

export const SubmitButton = styled.div`
  position: relative;
  top: 200%;
  border-radius: 28px;
  background-color: #143366;
  text-transform: uppercase;
  color: #FFFFFF;

  height: 44px;
  width: 160px;
  font-size: 20px;

  font-weight: 900;
  line-height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  &.disabled {
    cursor: not-allowed;
    background-color: #B9B9B9;
  }

  &:hover:not(.disabled) {
    opacity: 0.8;
  }
`