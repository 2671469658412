import styled from 'styled-components';

export const Mouse = styled.div`
  position: absolute;
  height: 60px;
  bottom: 30px;
  left: calc(50% - 30px);
  width: 60px;
  background-image: url(/assets/images/onboarding/mouse.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
`