import styled from 'styled-components';

export const Header = styled.div`
  text-align: center;
  padding: 1rem;
  height: 100px;
  background-color: #0046A9;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 101;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;