import styled from 'styled-components';

export const Women = styled.div`
  position: absolute;
  height: 90%;
  bottom: -100%;
  width: 60%;
  left: 45%;
  background-image: url(/assets/images/scenario3/women.png);
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
`