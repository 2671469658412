import styled from 'styled-components';

export const ComingSoon = styled.div`
  position: absolute;
  height: 100%;
  bottom: -15%;
  width: 40%;
  left: 55%;
  opacity: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  background-image: url(/assets/images/good2/coming_soon.png);
`