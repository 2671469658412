import styled from 'styled-components';
import { AnswerNumber } from './AnswerNumber';

export const AnswerBox = styled.div<{ wrong?: boolean, correct?: boolean, disabled?: boolean }>`
  position: relative;
  top: 100%;
  height: 100%;
  border: 4px solid #143366;
  width: 20%;
  color: #143366;
  padding: 1em;
  font-size: 0.65em;
  line-height: 1.1em;
  display: inline-block;
  border-radius: 10px;
  background-color: rgba(255,255,255,0.5);
  vertical-align: top;
  ${props => !props.disabled && 'cursor: pointer;'}
  ${props => props.wrong && 'border-color: #bc1d1d;'}
  ${props => props.correct && 'border-color: #3da217;'}
  -webkit-transition : border 500ms ease-out;
  -moz-transition : border 500ms ease-out;
  -o-transition : border 500ms ease-out;
  transition : border 500ms ease-out;

  & > ${AnswerNumber} {
    ${props => props.wrong && 'color: #bc1d1d;'}
    ${props => props.correct && 'color: #3da217;'}
    -webkit-transition : color 500ms ease-out;
    -moz-transition : color 500ms ease-out;
    -o-transition : color 500ms ease-out;
    transition : color 500ms ease-out;
  }

  & > svg {
    position: absolute;
    height: 40px;
    top: -20px;
    right: -20px;
  }

  &.active {
    background-color: #143366;
    color: white;
  }

  ${props => !props.disabled && `
    &:hover {
      background-color: rgba(255,255,255,0.9);
    }
  `}

  &.active:hover {
    background-color: #143366;
  }
`