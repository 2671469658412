import styled from 'styled-components';

export const MeetingRoom = styled.div`
  position: absolute;
  height: 100%;
  bottom: -100%;
  width: 100%;
  background-image: url('/assets/images/intro/meeting_room.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
`