import styled from 'styled-components';

export const AirplaneInternalBackground = styled.div`
  position: fixed;
  top: 100px;
  bottom: 0px;
  width: 100%;
  z-index: 102;
  background-image: url('/assets/images/onboarding/airplane_internal_bg.png');
  background-position: center 70%;
  background-size: cover;
  background-repeat: no-repeat;
`