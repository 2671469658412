import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { BocconiLogo } from './BocconiLogo';
import { FlymeLogoVert } from './FlymeLogoVert';
import { BuiltLogo } from './BuiltLogo';

const GameHeaderContainer = styled.div`
  height: 100px;
  text-align: center;
  background-color: #0046A9;
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

type GameHeaderProps = {
}

export const GameHeader = forwardRef<HTMLDivElement, GameHeaderProps>(
  (props: GameHeaderProps, ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined) => (
    <GameHeaderContainer ref={ref}>
      <BocconiLogo />
      <FlymeLogoVert />
      <BuiltLogo />
    </GameHeaderContainer>
  )
);