import styled from 'styled-components';

export const QuestionsBackground = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  left: 0%;
  bottom: 0;
  opacity: 0;
  background-color: rgba(255, 255, 255);
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
`