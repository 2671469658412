import React from 'react';

export const MenuIcon: React.FC = (props) => (
  <svg width="26px" height="16px" viewBox="0 0 26 16" version="1.1" >
    <g id="Introduction" stroke="none" strokeWidth="1" fill="inherit" fillRule="evenodd">
      <g id="INTRO-5" transform="translate(-1379.000000, -120.000000)" fill="inherit" fillRule="nonzero">
        <g id="Group-3" transform="translate(1364.000000, 100.000000)">
          <path d="M39.1476179,31.7894737 C40.1690091,31.7894737 41,32.7335435 41,33.8954515 C41,35.0031385 40.2428368,35.9137245 39.2856582,35.9942164 L39.1476179,36 L22.7233498,36 C21.7019587,36 20.8709677,35.0558855 20.8709677,33.8954515 C20.8709677,32.7863575 21.628131,31.8757483 22.5853095,31.7952572 L22.7233498,31.7894737 L39.1476179,31.7894737 Z M17.0967742,31.7894737 C18.2547906,31.7894737 19.1935484,32.7320321 19.1935484,33.8947368 C19.1935484,35.0574416 18.2547906,36 17.0967742,36 C15.9387578,36 15,35.0574416 15,33.8947368 C15,32.7320321 15.9387578,31.7894737 17.0967742,31.7894737 Z M39.1476179,25.8947368 C40.1690091,25.8947368 41,26.8388066 41,28.0007147 C41,29.1084017 40.2428368,30.0189876 39.2856582,30.0994796 L39.1476179,30.1052632 L22.7233498,30.1052632 C21.7019587,30.1052632 20.8709677,29.1611487 20.8709677,28.0007147 C20.8709677,26.8916206 21.628131,25.9810114 22.5853095,25.9005203 L22.7233498,25.8947368 L39.1476179,25.8947368 Z M17.0967742,25.8947368 C18.2547906,25.8947368 19.1935484,26.8372953 19.1935484,28 C19.1935484,29.1627047 18.2547906,30.1052632 17.0967742,30.1052632 C15.9387578,30.1052632 15,29.1627047 15,28 C15,26.8372953 15.9387578,25.8947368 17.0967742,25.8947368 Z M39.1476179,20 C40.1690091,20 41,20.9440698 41,22.1059778 C41,23.2136648 40.2428368,24.1242508 39.2856582,24.2047427 L39.1476179,24.2105263 L22.7233498,24.2105263 C21.7019587,24.2105263 20.8709677,23.2664118 20.8709677,22.1059778 C20.8709677,20.9968838 21.628131,20.0862746 22.5853095,20.0057835 L22.7233498,20 L39.1476179,20 Z M17.0967742,20 C18.2547906,20 19.1935484,20.9425584 19.1935484,22.1052632 C19.1935484,23.2679679 18.2547906,24.2105263 17.0967742,24.2105263 C15.9387578,24.2105263 15,23.2679679 15,22.1052632 C15,20.9425584 15.9387578,20 17.0967742,20 Z" id="menu-icon"></path>
        </g>
      </g>
    </g>
  </svg>
)