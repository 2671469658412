import React from 'react';
import { TimelineMax, Power4 } from "gsap";

type SceneClassProps = {
  duration?: number
}

class SceneClass<T = {}, S = {}> extends React.Component<T & SceneClassProps, S> {
  master: TimelineMax;
  timeFrame: number;

  constructor(props: T & SceneClassProps) {
    super(props);
    this.timeFrame = props.duration || (process.env.NODE_ENV === 'production' ? 2 : 0.8);
    this.master = new TimelineMax({ paused: true, ease: Power4.easeOut });
  }
}

export default SceneClass;