import React, { useState } from "react";
import { FormattedHTMLMessage } from "react-intl";
import download from 'downloadjs';
import { Base64 } from 'js-base64';

import { Scenario } from "../Scenario";
import { Scene } from "../Scene";
import { TextStrip } from "../TextStrip";
import { TextBlock } from "../TextBlock";

import { Background } from "./elements/Background";
import { ReportButton } from "./elements/ReportButton";
import Game from "../Game";
import { useParams } from "react-router-dom";
import { GameModelInstance } from "../../models/GameModel";

type ProfileProps = {
  id: string
}

const downloadReport = (game: GameModelInstance, setLoading: (state: boolean) => void) => {
  setLoading(true);
  const data = {
    scenarios: game.state.scenarios.slice(0, -1).map(scenario => ({
      scenarioId: scenario.scenarioId,
      questions: scenario.answers.map(answer => ({
        id: answer.questionID,
        answer: answer.value
      }))
    })),
    teamName: game.state.teamName,
    profile: game.state.currentScenario,
    date: new Date()
  }
  fetch(window._env_.REPORT_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data: Base64.encode(JSON.stringify(data)) })
  })
    .then( res => res.blob())
    .then( res => {
      download(res, 'flyme-report.pdf')
      setLoading(false);
    });

}

const Profile: React.FC<ProfileProps> = (props) => {
  const { gameId } = useParams<{ gameId: string }>();
  const [downloading, setDownloading] = useState(false);
  return (
    <Scenario>
      {/* SCENA 1 */}
      <Scene>
        <Background style={{ backgroundImage: `url(/assets/images/profiles/${props.id}.jpg)` }} />
      </Scene>

      {/* TEXT */}
      <TextStrip style={{ width: '70%', left: '26px', top: 0, fontSize: '0.85em', lineHeight: '1.4em' }}>
        <TextBlock style={{ top: 0 }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.2em' }}><FormattedHTMLMessage tagName="div" id={`${props.id}`} /></div>
          <br/>
          <FormattedHTMLMessage tagName="div" id={`${props.id}.text:1`} />
          <br/>
          <FormattedHTMLMessage tagName="div" id={`${props.id}.text:2`} />
          <br/>
          <FormattedHTMLMessage tagName="div" id={`${props.id}.text:3`} />
          <br/>
          <FormattedHTMLMessage tagName="div" id={`${props.id}.text:4`} />
          <br />
          <Game gameId={gameId}>
            {({ game }) => {
              return (
                <ReportButton onClick={() => !downloading && downloadReport(game, setDownloading)}>
                  {
                    downloading
                      ? 'Download in progress...'
                      : <FormattedHTMLMessage id="report.button" />
                  }
                </ReportButton>
              )
            }}
          </Game>
        </TextBlock>
      </TextStrip>
    </Scenario>
  )

}

export default Profile;