import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { FormattedHTMLMessage } from 'react-intl';

const ReadyGoContainer = styled.div`
  position: absolute;
  z-index: 10;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  font-size: 35px;
  font-weight: 300;
  line-height: 35px;
`
const WhiteBox = styled.div`
  margin-top: 20px;
  height: 86px;
  width: 224px;
  border-radius: 43px;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 50px;
  font-weight: 900;
  line-height: 55px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 20px 40px 0px;

  &:hover {
    opacity: 0.8;
    box-shadow: none;
  }
`
type ReadyGoProps = {
  onClick: (e: any) => void
}

export const ReadyGo = forwardRef<HTMLDivElement,ReadyGoProps>(
  (props: ReadyGoProps, ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined) => (
    <ReadyGoContainer ref={ref} onClick={props.onClick}>
      <FormattedHTMLMessage id="ready-set" />
      <WhiteBox>
        <FormattedHTMLMessage id="go" />
      </WhiteBox>
    </ReadyGoContainer>
  )
);