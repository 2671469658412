import React from "react";
import { QuestionsBackground } from "./QuestionsBackground";
import { Question } from "./Question";
import Game from "./Game";
import SceneClass from "./SceneClass";
import { withRouter, RouteComponentProps } from "react-router-dom";
import flatten from 'lodash/flatten';
import { FormattedHTMLMessage } from "react-intl";
import styled from "styled-components";

type QuestionsControllerProps = {
  questions: {
    id: string
    answers: number
  }[]
  active: boolean
  showFeedback?: boolean
} & RouteComponentProps<{ gameId: string }>

class QuestionsControllerR extends SceneClass<QuestionsControllerProps, { currentQuestion: number }> {
  background: HTMLDivElement | {} = {};

  constructor(props: QuestionsControllerProps) {
    super(props);
    this.state = { currentQuestion: 0 }
  }

  componentDidMount() {
    this.master
      .add('questions_controller_transition_1')
      .to(this.background, this.timeFrame * 0.1, { zIndex: '1' }, `questions_controller_transition_1`)
      .to(this.background, this.timeFrame, { opacity: '1' }, `questions_controller_transition_1`)
  }

  componentDidUpdate(props: QuestionsControllerProps) {
    if (this.props.active && this.master.progress() === 0) {
      this.master.play()
    }
  }

  render() {
    const { gameId } = this.props.match.params;
    return (
      <Game gameId={gameId || ''}>
        {({ game }) => (
          game.state.isCoordinator
            ? (
              <QuestionsBackground ref={div => div && (this.background = div)}>
                {
                  this.props.questions.map((question, index) => (
                    <Question key={index}
                      id={question.id}
                      answers={question.answers}
                      active={this.props.active && (game.state.currentQuestion === index)}
                      showFeedback={this.props.showFeedback}
                      value={this.props.showFeedback
                        ? flatten(game.state.scenarios
                          .map(scenario => scenario.answers))
                          .find(answer => answer.questionID === question.id)?.value
                        : undefined
                      }
                      correctValues={this.props.showFeedback
                        ? flatten(game.state.scenarios
                          .map(scenario => scenario.answers))
                          .find(answer => answer.questionID === question.id)?.correctValues
                        : undefined
                      }
                      onAdvance={game.advance}
                      onSubmit={(value) => {
                        if (this.props.showFeedback) {
                          game.answerToGym(question.id, value)
                        } else {
                          game.answer(question.id, value);
                        }
                      }} />
                  ))
                }
              </QuestionsBackground>
            )
            : (
              <QuestionsBackground ref={div => div && (this.background = div)}>
                {
                  this.props.questions.map((question, index) => (
                    <Question key={index}
                      id={question.id}
                      answers={question.answers}
                      readonly={true}
                      active={this.props.active && (this.state.currentQuestion === index)}
                      showFeedback={this.props.showFeedback}
                      value={this.props.showFeedback
                        ? flatten(game.state.scenarios
                          .map(scenario => scenario.answers))
                          .find(answer => answer.questionID === question.id)?.value
                        : undefined
                      }
                      correctValues={this.props.showFeedback
                        ? flatten(game.state.scenarios
                          .map(scenario => scenario.answers))
                          .find(answer => answer.questionID === question.id)?.correctValues
                        : undefined
                      }
                      onAdvance={() => this.setState({ currentQuestion: this.state.currentQuestion+1 })}
                      onSubmit={() => this.setState({ currentQuestion: this.state.currentQuestion+1 })} />
                  ))
                }
                {this.state.currentQuestion === this.props.questions.length && (
                  <Warning>
                    <FormattedHTMLMessage id="wait.for.coordinator" />
                  </Warning>
                )}
              </QuestionsBackground>
            )
          
        )}
      </Game>
    )
  }

}

export const QuestionsController = withRouter(QuestionsControllerR);

const Warning = styled.div`
  display: flex;
  height: 70%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`