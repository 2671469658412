import { types, Instance, SnapshotIn } from 'mobx-state-tree';
import { GameModel } from './GameModel';
import { UserModel } from './UserModel';

export const ViewModel = types.model('ViewModel')
  .props({
    games: types.map(GameModel),
    users: types.map(UserModel),
  })

  .views(self => ({
    getByID(id: string) {
      return self.games.get(id);
    },

    getUserByID(id: string) {
      return self.users.get(id);
    }
  }))

  .actions(self => ({
    loadByID(id: string) {
      if (!self.games.has(id)) {
        self.games.set(id, { id })
      }
    },

    loadUserByID(id: string) {
      if (!self.users.has(id)) {
        self.users.set(id, { id })
      }
    }
  }))

export interface ViewModelInstance extends Instance<typeof ViewModel> { }
export interface ViewModelSnapshotIn extends SnapshotIn<typeof ViewModel> { }