import styled from 'styled-components';

export const QuestionStatement = styled.div`
  position: relative;
  left: 100%;
  top: calc(20% + 3em);
  right: 10%;
  text-align: center;
  width: 55%;
  font-size: 1.1em;
  max-width: 1200px;
  color: #143366;
  font-weight: 500;
`