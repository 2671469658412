import { gql } from 'apollo-boost';

export default gql`
  subscription onGameUpdated($gameId: ID!) {
    gameUpdated {
      id
      startedAt
      currentScenario
      isCoordinator
      session {
        id
        name
        date
      }
      name
      teamName
      color
      channel {
        id
        color
        notReadCount(readerID: $gameId)
        messages {
          id
          text
          author
          sentAt
        }
      }

      broadcast {
        id
        color
        notReadCount(readerID: $gameId)
        messages {
          id
          text
          author
          sentAt
        }
      }

      scenarios {
        id
        scenarioId
        answers {
          questionID
          value
          correctValues
        }
      }
      
      planningScore
      communicationScore      
    }
  }
`;