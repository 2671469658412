import styled from 'styled-components';

export const MeetingTable = styled.div`
  position: absolute;
  height: 40%;
  bottom: -100%;
  left: 5%;
  right: 5%;
  background-image: url('/assets/images/intro/meeting_table.png');
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
`