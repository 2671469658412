import styled from 'styled-components';

export const PersonOnDesk = styled.div`
  position: absolute;
  height: 80%;
  bottom: 10%;
  width: 40%;
  left: 55%;
  opacity: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
`