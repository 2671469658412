import React, { useState } from 'react';
import { TimelineMax } from "gsap";
import { observer } from 'mobx-react';

type AnimatablePageProps = {
  duration: number
  master: TimelineMax
  children: React.FC<{ 
    handleClick: () => void
    handleTouchStart: (e: React.TouchEvent) => void
    handleTouchMove: (e: React.TouchEvent) => void
    handleTouchEnd: (e: React.TouchEvent) => void
    handleWheel: (e: React.WheelEvent) => void
    completed: () => boolean
  }>
  freeze: string
  autoPlay?: boolean
  irreversible?: boolean
}

export const AnimatablePage: React.FC<AnimatablePageProps> = observer((props) => {
  const [scrolling, setScrolling] = useState(false);
  const [delta, setDelta] = useState(0);
  const [completed,setCompleted] = useState(false);

  const scroll = ( delta: number ) => {
    if (props.master.progress() === 1) {
      // console.log("Completed!!", props.master.currentLabel())
      setCompleted(true);
    } else {
      if (!scrolling && props.freeze !== props.master.currentLabel()) {
        setScrolling(true);
        // console.log(
        //   "Scrolling.... ",
        //   props.master.time(),
        //   props.master.currentLabel(),
        //   props.master.progress()
        // );
        if (delta >= 0 && props.master.progress() < 1) {
          props.master.tweenTo(
            props.master.time() + props.duration,
            {
              onComplete: () => {
                setScrolling(false);
                if (props.master.progress() === 1) {
                  setCompleted(true);
                }
              }
            }
          );
        } else if (!props.irreversible && delta < 0 && props.master.progress() > 0 && props.master.progress() < 1) {
          props.master.tweenTo(props.master.time() - props.duration,
            { onComplete: () => setScrolling(false) }
          )
        } else {
          // console.log('Cannot scroll, timeline limit reached!')
          setScrolling(false)
        }
      } else {
        if (scrolling) {
          // console.log('Already scrolling!')
        } else {
          // console.log('Freeze!')
        }
      }
    }
  }

  const handleTouchStart = (e: React.TouchEvent) => {
    e.preventDefault();
    setDelta(e.touches[0].clientY);
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    setDelta(e.touches[0].clientY);
  }

  const handleTouchEnd = (e: React.TouchEvent) => {
    scroll(delta >= e.changedTouches[0].clientY ? 1 : -1);
    setDelta(0);
  }
  
  const handleWheel = (e: React.WheelEvent) => {
    scroll(e.deltaY);
  }

  const handleClick = () => {
    // console.log('click!!')
    if (props.master.progress() === 1) {
      // console.log("Completed!!")
      setCompleted(true);
    } else {
      if (!scrolling) {
        setScrolling(true);
        // console.log(
        //   "Scrolling.... ",
        //   props.master.time(),
        //   props.master.currentLabel()
        // );
        props.master.tweenTo(props.master.time() + props.duration,
          { onComplete: () => setScrolling(false) }
        );
      } else {
        // console.log('Already scrolling!')
      }
    }
  }

  if (props.autoPlay && props.master.progress() === 0 ) {
    handleClick()
  }

  return props.children({
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleWheel,
    handleClick,
    completed: () => completed
  });


})