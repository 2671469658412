import styled from 'styled-components';

export const Money3 = styled.div`
  position: absolute;
  height: 7vw;
  bottom: 65%;
  width: 7vw;
  left: 60%;
  opacity: 0;
  background-image: url(/assets/images/good1/money.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
`