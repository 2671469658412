import styled from "styled-components";

export const InputLabel = styled.div`
  color: #364281;
  text-indent: 1em;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: 0.5em;
`