import styled from 'styled-components';

export const SidebarTitle = styled.div`
  color: #143366;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.4rem;
  padding: 3rem;
  text-transform: uppercase;
`