import React from "react";
import { Scene } from "../Scene";
import { Header } from "../Intro/elements/Header";
import { BocconiLogo } from "../BocconiLogo";
import { BuiltLogoExt } from "../BuiltLogoExt";
import { AirplaneInternalBackground } from "../Intro/elements/AirplaneInternalBackground";
import { Porthole } from "./elements/Porthole";
import { Author } from "./elements/Author";
import { UserProvider } from "../UserProvider";
import { Redirect } from "react-router-dom";

export const Login = () => {
  return (
    <UserProvider endpoint={window._env_.USER_ENDPOINT}>
        {({ user }) => {
          if (user && user.id) return <Redirect to="/home" />;
          return (
            <Scene>
              <Header>
                <BocconiLogo />
                <BuiltLogoExt />
              </Header>
              <Porthole onClick={() => window.location.href = `${window._env_.AUTH_ENDPOINT}?r=${window.location.href}home`} />
              <AirplaneInternalBackground >
                <Author />
              </AirplaneInternalBackground>
            </Scene>
          )
        }}
      </UserProvider>
    
  )
}