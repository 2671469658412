import styled from 'styled-components';

export const SidebarBox = styled.div`
  height: calc(100vh - 100px);
  width: 100vw;
  position: fixed;
  z-index: 1000001;
  left: 0;
  top: 100px;
  left: 100%;
  opacity: 0;
  background-color: rgba(0,0,0,0.65);
  
  transition: opacity 0.3s;
`