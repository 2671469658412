import React, { Fragment } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Switch, Route, useLocation, Redirect, RouteComponentProps } from 'react-router-dom';
import Div100vh from 'react-div-100vh'
import last from 'lodash/last';

import { SmallDevicePage } from './SmallDevicePage';
import Game from './Game';
import Intro from './Intro';
import Good1 from './Good1';
import Bad1 from './Bad1';
import Good2 from './Good2';
import Bad2 from './Bad2';
import Good3 from './Good3';
import Bad3 from './Bad3';
import Gym from './Gym';
import Profile from './Profile';
import { GameHeader } from './GameHeader';
import { Chat } from './Chat';
import { History } from './History';
import Onboarding from './Onboarding';
import { ScenarioMapper } from '../models/ScenarioMapper';

type GameRouteHandlerProps = RouteComponentProps<{ gameId: string }>;

const PageContainer = styled(Div100vh)`
  position: relative;
  width: 100vw;
`;

const GameRouteHandler: React.SFC<GameRouteHandlerProps> = (props) => {

  const isSmall = useMediaQuery({ query: '(max-width: 1023px), (max-height: 649px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  let location = useLocation();
  const { gameId } = props.match.params;

  /**@TODO add user provider */
  // if (!user || !user.id) {
  //   window.location.href = `${window._env_.AUTH_ENDPOINT}?r=${window.location.href}/home`
  // }
  
  if (isSmall || isPortrait) {
    return <SmallDevicePage />
  } else {
    return (
      <Game gameId={gameId}>
        {({ game }) => {
          const currentPath = new ScenarioMapper(game.state.currentScenario).toPath();
          return (
            <PageContainer>
              {(currentPath !== last(location.pathname.split("/"))) && 
                <Redirect to={`/games/${game.id}/${currentPath}`} />}
              <GameHeader />
              {
                currentPath !== '2c38' && (
                  <Fragment>
                    <Chat />
                    { game.state.scenarios.length > 0 && <History />}
                  </Fragment>
                )
              }
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  classNames="page"
                  timeout={currentPath === '2c38' ? 0 : 2000} >
                  <Switch location={location}>
                    <Route path={`${props.match.path}/2c38`} children={<Onboarding gameId={game.id} />} />
                    <Route path={`${props.match.path}/5777`} children={<Intro gameId={game.id} />} />
                    <Route path={`${props.match.path}/2ad2`} children={<Good1 />} />
                    <Route path={`${props.match.path}/a2ff`} children={<Bad1 />} />
                    <Route path={`${props.match.path}/9ab5`} children={<Good2 />} />
                    <Route path={`${props.match.path}/23d3`} children={<Bad2 />} />
                    <Route path={`${props.match.path}/5bd6`} children={<Good3 />} />
                    <Route path={`${props.match.path}/573e`} children={<Bad3 />} />
                    <Route path={`${props.match.path}/494c`} children={<Gym questions={['gym.q1']} />} />
                    <Route path={`${props.match.path}/1a63`} children={<Gym questions={['gym.q2']} />} />
                    <Route path={`${props.match.path}/df44`} children={<Profile id="blossom" />} />
                    <Route path={`${props.match.path}/9513`} children={<Profile id="planner" />} />
                    <Route path={`${props.match.path}/2e17`} children={<Profile id="champion" />} />
                    <Route path={`${props.match.path}/ff3c`} children={<Profile id="expert" />} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </PageContainer>
          )
        }}
      </Game>
    )
  }
}

export default observer(GameRouteHandler);