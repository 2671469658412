import styled from 'styled-components';

export const LandingStrip = styled.div`
  position: absolute;
  height: 200%;
  bottom: 30%;
  width: 50%;
  background-image: url('/assets/images/onboarding/lines.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
`