import styled from 'styled-components';

export const TextBlock = styled.div` 
  position: absolute;
  top: 100vh;
  left: 0;
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 3rem;
  text-transform: uppercase;
`