import React from 'react';
import styled from 'styled-components';
import { FormattedHTMLMessage } from 'react-intl';

const AuthorBox = styled.div`
  position: relative;
  top: 0px;
  bottom: 0px;
  width: 40%;
  left: 60%;
  height: 100%;
  // background-color: red;
  display: flex;
  align-items: center;
  z-index: 105;
`

const FacultyBox = styled.div`
  position: absolute;
  bottom: 45%;
  width: 100%;
  padding: 1em;
  display: flex;
  margin-bottom: 2em;
  flex-direction: column;
  align-items: baseline;
  padding-left: 0;
`

type AuthorProps = {
  team?: {
    id: string
    color: string
  }
}

export const Author: React.FC<AuthorProps> = (props) => (
  <AuthorBox>
    <FacultyBox>
      {props.team && (
        <p style={{ fontSize: '1.5em' }}>
          <FormattedHTMLMessage id="welcome" /> <big><b style={{
            color: props.team.color,
            textTransform: 'uppercase',
            textShadow: '1px 1px 3px #484848'
          }}>{props.team.id}</b></big>
        </p>
      )}
      <br/>
      <FormattedHTMLMessage id="faculty.desc" />
    </FacultyBox>
    <img style={{
      height: '50%',
      position: 'relative',
      top: '25%',
    }} src="/assets/images/onboarding/passport.png" alt=""/>
  </AuthorBox>
)