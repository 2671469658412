import React, { useState, useEffect } from 'react';

type UserProviderProps = {
  endpoint: string
  children: React.FC<{ user: { id: string, isAdmin: boolean }, loading: boolean }>
}

export const UserProvider: React.FC<UserProviderProps> = (props) => {

  const [user, setUser] = useState({ id: '2', isAdmin: false });
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    fetch(props.endpoint)
      .then((res) => res.ok && res.json())
      .then((user) => {
        setUser(user.user);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      })

  }, [props.endpoint])

  return props.children({ user, loading })
}