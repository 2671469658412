import React from 'react';

export const Send: React.FC<{ fill?: string }> = (props) => (
  <svg width="23px" height="22px" viewBox="0 0 23 22" version="1.1">
    <g id="Chat" stroke="none" strokeWidth="1" fill="inherit" fillRule="evenodd">
      <g id="#1A_chat" transform="translate(-1357.000000, -832.000000)" fill="inherit" fillRule="nonzero">
        <g id="send" transform="translate(1340.000000, 816.000000)">
          <path d="M39.9999526,27.7870579 C39.7897578,28.3746186 39.3487227,28.8566474 38.7740133,29.1270121 L38.7740133,29.1270121 L20.4818047,37.7635348 C20.1511467,37.9191769 19.7887157,38 19.4215494,38 C19.1176926,37.9987068 18.8167243,37.9418074 18.5343652,37.8323037 C17.2872596,37.3486122 16.6782674,35.9704634 17.174136,34.7540992 L17.174136,34.7540992 L20.0317254,27.7870579 Z M17.2427488,17.3338635 C17.8267865,16.1586499 19.2769369,15.6677074 20.481852,16.2373023 L20.481852,16.2373023 L38.7740606,24.8706844 C39.34877,25.1410029 39.7897578,25.6230317 40,26.2106385 L40,26.2106385 L20.0317254,26.2106385 L17.174136,19.2451676 C16.920046,18.6270327 16.944953,17.9331089 17.2427488,17.3338635 Z" id="icon-send"></path>
        </g>
      </g>
    </g>
  </svg>
)