import styled from 'styled-components';

export const Money2 = styled.div`
  position: absolute;
  height: 8vw;
  bottom: 55%;
  width: 8vw;
  left: 50%;
  opacity: 0;
  background-image: url(/assets/images/good1/money.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
`