import React from "react";
import { FormattedHTMLMessage } from "react-intl";

import { Scenario } from "../Scenario";
import { Scene } from "../Scene";
import { AnimatablePage } from "../AnimatablePage";
import { QuestionsController } from "../QuestionsController";
import { TextStrip } from "../TextStrip";
import { TextBlock } from "./elements/TextBlock";

import { Alarm } from "./elements/Alarm";
import { AlarmBW } from "./elements/AlarmBW";
import SceneClass from "../SceneClass";

type GymProps = {
  questions: string[]
}

export default class Gym extends SceneClass<GymProps> {
  scene_1: HTMLDivElement | {} = {};
  alarm: HTMLDivElement | {} = {};
  alarm_bw: HTMLDivElement | {} = {};

  textStrip: HTMLDivElement | {} = {};
  textBlock_1: HTMLDivElement | {} = {};

  componentDidMount() {
    this.master
      .add('transition_1')
      .to(this.alarm, this.timeFrame, { opacity: 1 }, 'transition_1')
      .to(this.textStrip, this.timeFrame, { top: '0px' }, 'transition_1')

      .add('transition_2')
      .to(this.textStrip, this.timeFrame, { top: '-100%' }, 'transition_2')
  }

  render() {
    return (
      <AnimatablePage master={this.master} duration={this.timeFrame} freeze="" autoPlay>
        {({ handleWheel, handleTouchStart, handleTouchEnd, completed}) => {
          return (
            <Scenario onWheel={handleWheel.bind(this)} 
              onTouchEnd={handleTouchEnd.bind(this)}
              onTouchStart={handleTouchStart.bind(this)}>
              {/* SCENA 1 */}
              <Scene ref={div => div && (this.scene_1 = div)}>
                <AlarmBW ref={div => div && (this.alarm_bw = div)} />
                <Alarm ref={div => div && (this.alarm = div)} />
              </Scene>

              {/* TEXT */}
              <TextStrip ref={div => div && (this.textStrip = div)}>
                <TextBlock ref={div => div && (this.textBlock_1 = div)}
                  style={{ top: '0%' }}>
                  <FormattedHTMLMessage id="gym.text:1" />
                </TextBlock>
              </TextStrip>

              {/* QUESTIONS */}
              <QuestionsController active={completed()}
                showFeedback
                questions={this.props.questions.map( id => ({ id, answers: 3 }) )} />
            </Scenario>
          )
        }}
      </AnimatablePage>
    )
  }

}