import styled from 'styled-components';
import { observer } from 'mobx-react';

export const ChatButton = observer(styled.div<{ notRead?: boolean, disabled?: boolean }>`
  height: 60px;
  width: 60px;
  position: fixed;
  right: 2em;
  top: 7em;
  z-index: 20;
  background: #0046a9;
  border-radius: 50%;
  border: 2px solid ${props => props.notRead ? '#fe9701' : 'white'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => !props.disabled ? 'pointer' : 'default'};
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.08);

  ${props => !props.disabled && `
    &:hover {
      box-shadow: none;
    }
  `};

  & svg {
    fill: ${props => props.notRead ? '#fe9701' : 'white'};
    height: auto;
    width: 50%;
  }
`)