import React from "react";
import { Scenario } from "../Scenario";
import { SkyBackground } from "../SkyBackground";
import { Skyline } from "../Skyline";
import { Scene } from "../Scene";
import { AnimatablePage } from "../AnimatablePage";
import { QuestionsController } from "../QuestionsController";
import { TextStrip } from "../TextStrip";
import { TextBlock } from "../TextBlock";
import { ScenarioTitle } from "../ScenarioTitle";
import { FormattedHTMLMessage } from "react-intl";

import { Man } from "./elements/Man";
import { Man2 } from "./elements/Man2";
import { Umbrella } from "./elements/Umbrella";
import { Roof } from "./elements/Roof";
import { Rain } from "./elements/Rain";
import { Floor } from "./elements/Floor";
import { Table } from "./elements/Table";
import { Sofa } from "./elements/Sofa";
import { Blur } from "./elements/Blur";
import { Ipad } from "./elements/Ipad";
import SceneClass from "../SceneClass";

export default class Bad1 extends SceneClass {
  scene_1: HTMLDivElement | {} = {};
  rain: HTMLDivElement | {} = {};
  man: HTMLDivElement | {} = {};
  man_2: HTMLDivElement | {} = {};
  umbrella: HTMLDivElement | {} = {};

  scene_2: HTMLDivElement | {} = {};
  table: HTMLDivElement | {} = {};
  sofa_1: HTMLDivElement | {} = {};
  sofa_2: HTMLDivElement | {} = {};
  blur: HTMLDivElement | {} = {};
  ipad: HTMLDivElement | {} = {};

  textStrip: HTMLDivElement | {} = {};
  textBlock_1: HTMLDivElement | {} = {};
  textBlock_2: HTMLDivElement | {} = {};
  textBlock_3: HTMLDivElement | {} = {};
  textBlock_4: HTMLDivElement | {} = {};
  textBlock_5: HTMLDivElement | {} = {};

  componentDidMount() {
    this.master
      .add('transition_1')
      .to(this.man, this.timeFrame, { opacity: 1 }, 'transition_1')
      .to(this.umbrella, this.timeFrame, { opacity: 1 }, 'transition_1')
      .to(this.rain, this.timeFrame, { opacity: 1 }, 'transition_1')
      .to(this.textStrip, this.timeFrame, { top: '0px' }, 'transition_1')

      .add('transition_2')
      .to(this.umbrella, this.timeFrame * 0.5, { opacity: 0 }, 'transition_2')
      .to(this.man_2, this.timeFrame * 0.5, { opacity: 1 }, `transition_2+=${this.timeFrame * 0.5}`)
      .to(this.textBlock_1, this.timeFrame, { top: '-100%' }, 'transition_2')
      .to(this.textBlock_2, this.timeFrame, { top: '0%' }, 'transition_2')

      .add('transition_3')
      .to(this.scene_1, this.timeFrame, { top: '-100%' }, 'transition_3')
      .to(this.scene_2, this.timeFrame, { top: '-100%' }, 'transition_3')
      .to(this.textBlock_2, this.timeFrame, { top: '-100%' }, 'transition_3')
      .to(this.textBlock_3, this.timeFrame, { top: '0%' }, 'transition_3')

      .add('transition_4')
      .to(this.sofa_1, this.timeFrame, {opacity: 1 }, 'transition_4')
      .to(this.sofa_2, this.timeFrame, {opacity: 1 }, 'transition_4')
      .to(this.textBlock_3, this.timeFrame, { top: '-100%' }, 'transition_4')
      .to(this.textBlock_4, this.timeFrame, { top: '0%' }, 'transition_4')

      .add('transition_5')
      .to(this.blur, this.timeFrame, { opacity: 1 }, `transition_5`)
      .to(this.ipad, this.timeFrame, { left: '50%' }, `transition_5`)
      .to(this.textBlock_4, this.timeFrame, { top: '-100%' }, 'transition_5')
      .to(this.textBlock_5, this.timeFrame, { top: '0%' }, 'transition_5')

      .add('transition_6')
      .to(this.ipad, this.timeFrame, { left: '100%' }, `transition_6`)
      .to(this.textStrip, this.timeFrame, { top: '-100%' }, 'transition_6')
  }

  render() {
    return (
      <AnimatablePage master={this.master} duration={this.timeFrame} freeze="" autoPlay>
        {({ handleWheel, handleTouchStart, handleTouchEnd, completed}) => {
          return (
            <Scenario onWheel={handleWheel.bind(this)} 
              onTouchEnd={handleTouchEnd.bind(this)}
              onTouchStart={handleTouchStart.bind(this)}>
              {/* SCENA 1 */}
              <Scene ref={div => div && (this.scene_1 = div)}>
                <SkyBackground />
                <Skyline style={{ bottom: 0 }} />
                <Roof style={{ bottom: 0 }} />
                <Rain ref={div => div && (this.rain = div)} />
                <Man ref={div => div && (this.man = div)} />
                <Man2 ref={div => div && (this.man_2 = div)} />
                <Umbrella ref={div => div && (this.umbrella = div)} />
              </Scene>

              {/* SCENA 2 */}
              <Scene ref={div => div && (this.scene_2 = div)}>
                <Floor />
                <Table ref={div => div && (this.table = div)} />
                <Sofa ref={div => div && (this.sofa_1 = div)}
                  style={{ backgroundImage: 'url(/assets/images/bad1/sofa_1.png)' }} />
                <Sofa ref={div => div && (this.sofa_2 = div)}
                  style={{ backgroundImage: 'url(/assets/images/bad1/sofa_2.png)' }} />
                <Blur ref={div => div && (this.blur = div)} />
                <Ipad ref={div => div && (this.ipad = div)} />
              </Scene>

              {/* TEXT */}
              <TextStrip ref={div => div && (this.textStrip = div)}>
                <TextBlock ref={div => div && (this.textBlock_1 = div)} style={{ top: '0%' }}>
                  <ScenarioTitle scenario="bad1">
                    <FormattedHTMLMessage id="bad1.text:1" />
                  </ScenarioTitle>
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_2 = div)}>
                  <FormattedHTMLMessage id="bad1.text:2" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_3 = div)}>
                  <FormattedHTMLMessage id="bad1.text:3" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_4 = div)}>
                  <FormattedHTMLMessage id="bad1.text:4" />
                </TextBlock>
                <TextBlock ref={div => div && (this.textBlock_5 = div)}>
                  <FormattedHTMLMessage id="bad1.text:5" />
                </TextBlock>
              </TextStrip>

              {/* QUESTIONS */}
              <QuestionsController questions={[
                'scenario1.q1',
                'scenario1.q2',
                'scenario1.q3'
              ].map( id => ({ id, answers: 5 }) )} active={completed()} />
            </Scenario>
          )
        }}
      </AnimatablePage>
    )
  }

}