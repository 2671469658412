import { gql } from 'apollo-boost';

export default gql`
  query UserQuery {
    user {
      id
      displayName
      isAdmin
      games {
        id
        session {
          id
          name
          date
        }
      }
    }
  }
`