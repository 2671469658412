import styled from 'styled-components';

export const PC = styled.div`
  position: absolute;
  height: 50%;
  bottom: 5%;
  width: 44%;
  left: 52%;
  background-image: url(/assets/images/scenario3/pc.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
`