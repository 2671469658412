import styled from 'styled-components';

export const SkyBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0px;
  width: 100%;
  background-image: url('/assets/images/sky.jpg');
  background-position: 100% 0%;
  background-size: 150%;
  background-repeat: no-repeat;
`