import React from 'react';
import './App.css';
import { IntlProvider } from 'react-intl';
import { createBrowserHistory } from 'history';
import { Router, Switch, Route } from "react-router-dom";

import GameRouteHandler from './components/GameRouteHandler';
import { ApolloProvider } from '@apollo/react-hooks';
import { StoreProvider } from './models/GameStoreProvider';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';

import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { Login } from './components/Login';
import { Home } from './components/Home';

declare global {
  interface Window {
    _env_: {
      API_ENDPOINT: string;
      ADMIN_ENDPOINT: string;
      INTL_ENDPOINT: string;
      SUBS_ENDPOINT: string;
      REPORT_ENDPOINT: string;
      USER_ENDPOINT: string;
      AUTH_ENDPOINT: string;
      BASEPATH: string;
      apolloClient: ApolloClient<NormalizedCacheObject>;
    }
  }
}

const httpLink = new HttpLink({
  uri: window._env_.API_ENDPOINT
});

const wsLink = new WebSocketLink({
  uri: window._env_.SUBS_ENDPOINT,
  options: {
    reconnect: true,
  }
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

window._env_.apolloClient = client;

const history = createBrowserHistory({
  basename: window._env_.BASEPATH
});

const App: React.FC<{ messages: Record<string, string> }> = (props) => {

  return (
    <Router history={history}>
      <ApolloProvider client={client}>
        <StoreProvider>
          <IntlProvider locale="en" messages={props.messages}>
            <Switch>
              <Route path="/games/:gameId" component={GameRouteHandler} />
              <Route path="/home" component={Home} />
              <Route path="/" component={Login} />
            </Switch>
          </IntlProvider>
        </StoreProvider>
      </ApolloProvider>
    </Router>
  );
}

export default App;
