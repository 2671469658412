import styled from 'styled-components';

export const AnswersBox = styled.div`
  position: relative;
  min-height: 320px;
  width: 90%;
  max-width: 1250px;
  top: 4em;
  display: flex;
  justify-content: space-evenly;
`