import React from 'react';
import { SidebarBox } from './SidebarBox';
import { SidebarContent } from './SidebarContent';

type SidebarProps = {
  active: boolean
  onClose: () => void
}

export const Sidebar: React.FC<SidebarProps> = ({ active = false, onClose, children }) => {

  return (
    <SidebarBox style={{ left: active ? '0' : '100%', opacity: active ? 1 : 0 }} onClick={onClose}>
      <SidebarContent style={{ right: active ? '0' : '-460px', width: 450 }} onClick={(e) => { e.stopPropagation(); }}>
        {children}
      </SidebarContent>
    </SidebarBox>
  )
}