import styled from 'styled-components';

export const Money1 = styled.div`
  position: absolute;
  height: 9vw;
  bottom: 50%;
  width: 9vw;
  left: 62%;
  opacity: 0;
  background-image: url(/assets/images/good1/money.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;
`