import React from "react";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import { Scene } from "../Scene";
import { UserProvider } from "../UserProvider";
import User from "../User";
import { SkyBackground } from "../SkyBackground";
import { Cell, HeaderCell, HeaderRow, LinkButton, Row, Table, Header as TableHeader } from "../Table";
import { GameHeader } from "../GameHeader";

export const Home = () => {
  return (
    <Scene>
      <GameHeader />
      <UserProvider endpoint={window._env_.USER_ENDPOINT}>
        {({ user }) => {
          if (!user || !user.id) { 
            window.location.href = `${window._env_.AUTH_ENDPOINT}?r=${window.location.href}home`;
            return null;
          }
          return (
            <User userID={user.id}>
              {({ user }) => {
                if (!user.state.isAdmin && user.state.games.length === 1) {
                  return <Redirect to={`/games/${user.state.games[0].id}`} />
                }
                return (
                  <HomeBox>
                    <SkyBackground>
                      <HomePageContent>
                        <HomeHeader>
                          <Welcome>
                            Welcome <b>{user.state.displayName}</b>
                          </Welcome>
                          {user.state.isAdmin &&
                            <AdminDashboardLink href={window._env_.ADMIN_ENDPOINT}>
                              Admin dashboard
                          </AdminDashboardLink>}
                        </HomeHeader>
                        
                        <div>
                          <Table>
                            <TableHeader>
                              <HeaderRow>
                                <HeaderCell></HeaderCell>
                                <HeaderCell>Session Name</HeaderCell>
                                <HeaderCell>Date</HeaderCell>
                                <HeaderCell>Link</HeaderCell>
                              </HeaderRow>
                            </TableHeader>
                            {user.state.games.reverse().map(game => (
                              <Row key={game.id}>
                                <Cell>
                                  <AirplaneIcon />
                                </Cell>
                                <Cell>{game.session.name}</Cell>
                                <Cell>{game.session.date}</Cell>
                                <Cell>
                                  <GameLink to={`/games/${game.id}`} />
                                </Cell>
                              </Row>
                            ))}
                            {user.state.games.length === 0 && (
                              <Row>
                                <Cell/>
                                <Cell>
                                  You have no games to play.
                                </Cell>
                              </Row>
                            )}
                          </Table>
                        </div>
                      </HomePageContent>
                    </SkyBackground>
                  </HomeBox>
                )
              }}
            </User>
          )
        }}
      </UserProvider>
    </Scene>
  )
}

const HomeBox = styled.div`
  position: relative;
  height: calc(100vh - 100px);
`;

const HomePageContent = styled.div`
  height: 100%;
  width: 100%;
  max-width: 940px;
  margin: auto;
  padding: 3em 0;
`;

const HomeHeader = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
`

const Welcome = styled.div`
  color: #333333;
`

const AdminDashboardLink = styled.a`
  height: 50px;
  width: 190px;
  border-radius: 6px;
  background-color: #5C85DC;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
`
const AirplaneIcon = () => (
  <img src="assets/images/home/airplane.svg" alt="airplane" />
)

const GameLink: React.FC<{ to: string }> = (props) => (
  <Link to={props.to}>
    <LinkButton />
  </Link>
)