import React from 'react';
import { useLocalStore } from "mobx-react";
import { ViewModel } from "./ViewModel";
import { ViewModelInstance } from "./ViewModel";

const storeContext = React.createContext<ViewModelInstance | null>(null);

const createStore = () => ViewModel.create({}, { 
  client: window._env_.apolloClient
});

export const StoreProvider: React.SFC = ({ children }) => {
  const store = useLocalStore(createStore);
  return (
    <storeContext.Provider value={ store }>
      {children}
    </storeContext.Provider>
  );
}

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) { throw new Error(`useStore must be used within a StoreProvider`) }
  else return store;
}