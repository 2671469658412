import { types } from 'mobx-state-tree';

export const Channel = types.model({
  id: types.string,
  color: types.string,
  notReadCount: types.number,
  messages: types.array(types.model({
    id: types.string,
    text: types.string,
    author: types.string,
    sentAt: types.number,
  }))
})