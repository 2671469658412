import React, { useRef } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import { Avatar } from './Avatar';
import { useParams } from 'react-router-dom';
import Game from '../Game';
import orderBy from 'lodash/orderBy';
import { MessageInput } from './MessageInput';
import { MessagesBox } from './MessagesBox';
import { Message } from './Message';
import { MessageText } from './MessageText';
import { Pin } from './Pin';
import { Megaphone } from './Megaphone';
import { observer } from 'mobx-react';
import { TeacherAvatar } from './TeacherAvatar';

const ChannelMessagesBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 115px);
  align-items: center;
`

export const ChannelMessages = observer<React.FC>(() => {
  const { gameId } = useParams<{ gameId: string }>();
  const box = useRef<Scrollbars>(null);

  const handleScrollToBottom = () => {
    setTimeout(() => {
      if (box && box.current && box.current.scrollToBottom) {
        box.current.scrollToBottom();
      }
    }, 1)
  }

  return (
    <Game gameId={gameId || ''}>
      {({ game }) => {
        if (!game.state.channel || !game.state.broadcast) {
          return null;
        }
        handleScrollToBottom()
        return (
          <ChannelMessagesBox>
            <Scrollbars ref={box} style={{ maxWidth: '700px', height: '100%' }}>
              <MessagesBox>
                {
                  orderBy([
                    ...game.state.channel.messages.map( message => ({ ...message, broadcast: false }) ), 
                    ...game.state.broadcast.messages.map(message => ({ ...message, broadcast: true }))
                  ], 'sentAt')
                    .map((message, index) => {
                      const own = message.author === gameId;
                      return (
                        <Message key={index} own={own}>
                          {!own && (
                            <Avatar color={!own ? '#364281' : game.state.channel?.color} own={own}>
                              {
                                message.broadcast
                                  ? <Megaphone />
                                  : <TeacherAvatar />
                              }
                            </Avatar>
                          )}
                          <MessageText color={!own ? '#364281' : game.state.channel?.color} own={own}>
                            <Pin color={!own ? '#364281' : game.state.channel?.color} own={own} />
                            {message.text.split("\n").map( (t,i) => <p key={i}>{t}</p> )}
                          </MessageText>
                          {own && <Avatar color={game.state.channel?.color} own={own} />}
                        </Message>
                      )
                    })
                }
              </MessagesBox>
            </Scrollbars>
            {
              !game.readOnly
                ? <MessageInput onSend={(text: string) => game.sendMessage(text)} />
                : <span style={{ height: 145 }}>Solo il team leader può inviare messaggi</span>
            }
            
          </ChannelMessagesBox>
        )
      }}
    </Game>
  )
})