import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

export const ScenarioTitle: React.FC<{ scenario: string }> = 
  ({ scenario, children }) => (
  <div>
    <b style={{textTransform : 'uppercase'}}><FormattedHTMLMessage id={`${scenario}.title`} /></b>
    <br />
    <br />
    {children}
  </div>
)