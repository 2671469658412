import styled from 'styled-components';

export const Pin = styled.div<{ own: boolean }>`
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: ${props => props.color};
  transform: rotate(45deg);
  ${props => !props.own ? 'left' : 'right'}: -7px;
  top: 10px;
`

