import { gql } from 'apollo-boost';

export default gql`
  subscription onMessageSent {
    messageSent {
      id
      messages {
        id
        author
        text
      }
    }
  }
`;