import { getEnv, types, Instance, SnapshotIn, applySnapshot, SnapshotOut } from 'mobx-state-tree';
import { gql, NormalizedCacheObject } from 'apollo-boost';
import { ApolloClient } from 'apollo-client';
import GameQuery from '../queries/GameQuery';
import { Scenario, ScenarioInstance } from './Scenario';
import { Channel } from './Channel';
import MessageSubscription from '../subscriptions/MessageSubscription';
import GameSubscription from '../subscriptions/GameSubscription';

const Session = types.model('Session')
  .props({
    id: types.string,
    name: types.string,
    date: types.string,
  })

export const GameModel = types.model('GameModel')
  .props({
    id: types.string,
    loading: false,
    errors: types.array(types.string),
    state: types.optional(types.model({
      startedAt: types.maybeNull(types.number),
      isCoordinator: false,
      currentScenario: '',
      currentQuestion: 0,
      planningScore: 0,
      communicationScore: 0,
      session: types.optional(Session, { id: '', name: '', date: '' }),
      teamName: '',
      name: '',
      color: '',
      channel: types.maybeNull(Channel),
      broadcast: types.maybeNull(Channel),
      scenarios: types.array(Scenario),
      answers: types.array(types.model({
        questionID: types.string,
        value: types.number
      }))
    }), {})
  })

  .actions(self => ({
    setStateSnapshot(state: GameModelSnapshotOut) {
      try {
        applySnapshot(self.state, state)
      } catch (error) {
        console.error(error)
      }
    },

    setLoading(value: boolean) {
      self.loading = value;
    },

    setErrors(errors: string[]) {
      self.errors.replace(errors);
    }

  }))

  .views(self => ({
    get client() {
      return getEnv<{ client: ApolloClient<NormalizedCacheObject> }>(self)
        .client
    },

    get readOnly() {
      return !self.state.isCoordinator
    }
  }))

  .actions(self => ({
    load() {
      self.client
        .query({ query: GameQuery, variables: { gameId: self.id }, fetchPolicy: 'network-only' })
        .then((state) => {
          if (state.errors && state.errors.length) {
            self.setErrors(state.errors.map(err => err.message));
          } else {
            self.setErrors([]);
            self.setStateSnapshot({
              ...state.data.user.game,
              currentQuestion: self.state.currentQuestion,
              answers: self.state.answers
            });
            self.setLoading(false);
          }
        })
        .catch(err => self.setErrors([err.message]))
    },

    setCurrentQuestion( value: number ) {
      self.state.currentQuestion = value;
    },

    setCurrentScenario(scenario: string) {
      self.state.currentScenario = scenario;
    },

    updateScenarios(scenarios: ScenarioInstance[]) {
      self.state.scenarios.replace(scenarios);
    },

    setStartedAt(value: number) {
      self.state.startedAt = value;
    },

    resetAnswers() {
      self.state.answers.replace([]);
    },

    setScores( planningScore: number, communicationScore: number ) {
      self.state.planningScore = planningScore;
      self.state.communicationScore = communicationScore;
    }
  }))

  .actions(self => ({
    subscribe() {
      self.client.subscribe({
        query: MessageSubscription,
        variables: { sessionID: self.id },
        fetchPolicy: 'network-only',
      }).subscribe({
        next(data) {
          // console.log('update after messageSent event')
          self.load();
        }
      })
      self.client.subscribe({
        query: GameSubscription,
        variables: { gameId: self.id },
        fetchPolicy: 'network-only',
      }).subscribe({
        next(res) {
          // console.log('update after gameUpdated event')
          if (res.data.gameUpdated.id === self.id) {
            self.load();
          }
        }
      })
    },

    updateLastRead() {
      self.state.channel && (self.state.channel.notReadCount = 0);
      self.state.broadcast && (self.state.broadcast.notReadCount = 0);
    }
  }))

  .actions(self => ({
    afterCreate() {
      self.load();
      self.subscribe();
    },
    
    start() {
      if (self.state.isCoordinator) {
        self.client.mutate({
          mutation: gql`
          mutation StartGame($gameId:ID!) {
            startGame( gameId:$gameId ) {
              id
              startedAt
              currentScenario
            }
          }
        `,
          variables: {
            gameId: self.id,
          }
        })
          .then(res => {
            self.setStartedAt(res.data.startGame.startedAt);
            self.setCurrentScenario(res.data.startGame.currentScenario);
          })
      }
    },

    setTeamNameAndLocation(teamName: string, location: string) {
      if (self.state.isCoordinator) {
        self.client.mutate({
          mutation: gql`
            mutation SetTeamNameAndLocation($gameId:ID!, $teamName:String!, $location: String) {
              setTeamNameAndLocation( gameId:$gameId, teamName:$teamName, location:$location ) {
                id
              }
            }
          `,
          variables: {
            gameId: self.id,
            teamName,
            location
          }
        })
          .then(res => {
            self.load();
          })
      }
    },

    closeOnboarding() {
      if (self.state.isCoordinator) {
        self.client.mutate({
          mutation: gql`
            mutation StartGame($gameId:ID!) {
              closeGameOnboarding( gameId:$gameId ) {
                id
                currentScenario
              }
            }
          `,
          variables: {
            gameId: self.id,
          }
        })
          .then(res => {
            self.setCurrentScenario(res.data.closeGameOnboarding.currentScenario);
          })
      }
    },

    advance() {
      if (self.state.isCoordinator) {
        self.client.mutate({
          mutation: gql`
            mutation Advance($gameId:ID!) {
              advance( gameId:$gameId ) {
                id
                currentScenario
              }
            }
          `,
          variables: {
            gameId: self.id,
          }
        })
          .then(res => {
            self.setCurrentScenario(res.data.advance.currentScenario);
          })
      }
    },

    answer(questionID: string, value: number) {
      if (self.state.isCoordinator) {
        self.state.answers.push({ questionID, value });
        if (self.state.answers.length === 3 || self.state.currentScenario.match(/gym/)) {
          self.client.mutate({
            mutation: gql`
              mutation AnswerToScenario($gameId:ID!, $scenarioId: String!, $answers:[AnswerInput]!) {
                answerToScenario( gameId:$gameId, scenarioId:$scenarioId, answers:$answers ) {
                  id
                  currentScenario
                  planningScore
                  communicationScore
                  scenarios {
                    id
                    scenarioId
                    answers {
                      questionID
                      value
                    }
                  }
                }
              }
            `,
            variables: {
              gameId: self.id,
              scenarioId: self.state.currentScenario,
              answers: self.state.answers
            }
          })
          .then( res => {
            self.setCurrentScenario(res.data.answerToScenario.currentScenario);
            self.updateScenarios(res.data.answerToScenario.scenarios);
            self.setScores(res.data.answerToScenario.planningScore, res.data.answerToScenario.communicationScore);
            self.resetAnswers();
            self.setCurrentQuestion(0);
          })
        } else {
          self.setCurrentQuestion(self.state.currentQuestion + 1);
        }
      }
    },

    answerToGym(questionID: string, value: number) {
      if (self.state.isCoordinator) {
        self.state.answers.push({ questionID, value });
        self.client.mutate({
          mutation: gql`
              mutation AnswerToGym($gameId:ID!, $scenarioId: String!, $answers:[AnswerInput]!) {
                answerToGym( gameId:$gameId, scenarioId:$scenarioId, answers:$answers ) {
                  id
                  currentScenario
                  planningScore
                  communicationScore
                  scenarios {
                    id
                    scenarioId
                    answers {
                      questionID
                      value
                      correctValues
                    }
                  }
                }
              }
            `,
          variables: {
            gameId: self.id,
            scenarioId: self.state.currentScenario,
            answers: self.state.answers
          }
        })
          .then(res => {
            self.updateScenarios(res.data.answerToGym.scenarios);
            self.setScores(res.data.answerToGym.planningScore, res.data.answerToGym.communicationScore);
            self.resetAnswers();
            self.setCurrentQuestion(0);
          })
      }
    },

    sendMessage(text: string) {
      self.client.mutate({
        mutation: gql`
          mutation SendMessage($channelID:ID!, $author:String!, $text:String!) {
            sendMessage( author:$author, channelID:$channelID, text:$text ) {
              id
              messages {
                id
                text
                author
                channelID
                sentAt
              }
            }
          }
        `,
        variables: {
          channelID: self.id,
          author: self.id,
          text
        }
      })
    },

    readChannel() {
      self.client.mutate({
        mutation: gql`
          mutation ReadGameChannel($gameId:ID!) {
            readGameChannel( gameId:$gameId ) {
              id
              channel {
                id
                lastRead(readerID: $gameId)
              }
              broadcast {
                id
                lastRead(readerID: $gameId)
              }
            }
          }
        `,
        variables: {
          gameId: self.id
        }
      })
        .then(res => {
          self.updateLastRead();
        })
    }
  }))

export interface GameModelInstance extends Instance<typeof GameModel> { }
export interface GameModelSnapshotIn extends SnapshotIn<typeof GameModel> { }
export interface GameModelSnapshotOut extends SnapshotOut<typeof GameModel> { }