import styled from 'styled-components';

export const ManOnDesk = styled.div`
  position: absolute;
  height: 100%;
  bottom: -15%;
  width: 40%;
  left: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  background-image: url(/assets/images/good2/man_on_desk.png);
`