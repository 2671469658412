import { getEnv, types, Instance, SnapshotIn, applySnapshot, SnapshotOut } from 'mobx-state-tree';
import { gql, NormalizedCacheObject } from 'apollo-boost';
import { ApolloClient } from 'apollo-client';
import UserQuery from '../queries/UserQuery';
import MessageSubscription from '../subscriptions/MessageSubscription';

const HomeGameModel = types.model('HomeGameModel')
  .props({
    id: '',
    session: types.model({ id: '', name: '', date: '' })
  })

export const UserModel = types.model('UserModel')
  .props({
    id: types.string,
    loading: false,
    errors: types.array(types.string),
    state: types.optional(types.model({
      id: '',
      displayName: '',
      isAdmin: false,
      isInstructor: false,
      games: types.array(HomeGameModel)
    }), {})
  })

  .actions(self => ({
    setStateSnapshot(state: UserModelSnapshotOut) {
      try {
        applySnapshot(self.state, state)
      } catch (error) {
        console.error(error)
      }
    },

    setLoading(value: boolean) {
      self.loading = value;
    },

    setErrors(errors: string[]) {
      self.errors.replace(errors);
    }

  }))

  .views(self => ({
    get client() {
      return getEnv<{ client: ApolloClient<NormalizedCacheObject> }>(self)
        .client
    }
  }))

  .actions(self => ({
    load() {
      self.client
        .query({ query: UserQuery, variables: {}, fetchPolicy: 'network-only' })
        .then((state) => {
          if (state.errors && state.errors.length) {
            self.setErrors(state.errors.map(err => err.message));
          } else {
            self.setErrors([]);
            self.setStateSnapshot({
              ...state.data.user,
            });
            self.setLoading(false);
          }
        })
        .catch(err => self.setErrors([err.message]))
    }
  }))

  .actions(self => ({
    subscribe() {
      self.client.subscribe({
        query: MessageSubscription,
        variables: { sessionID: self.id },
        fetchPolicy: 'network-only',
      }).subscribe({
        next(data) {
          console.log('update after messageSent event')
          self.load();
        }
      })
    },
  }))

  .actions(self => ({
    afterCreate() {
      self.load();
      self.subscribe();
    },
    
    start() {
      self.client.mutate({
        mutation: gql`
          mutation StartGame($gameId:ID!) {
            startGame( gameId:$gameId ) {
              id
              startedAt
              currentScenario
            }
          }
        `,
        variables: {
          gameId: self.id,
        }
      })
      .then( res => {

      })
    },
    
  }))

export interface UserModelInstance extends Instance<typeof UserModel> { }
export interface UserModelSnapshotIn extends SnapshotIn<typeof UserModel> { }
export interface UserModelSnapshotOut extends SnapshotOut<typeof UserModel> { }