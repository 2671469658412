import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import range from 'lodash/range';

import { QuestionBox } from "./QuestionBox";
import { QuestionDescription } from "./QuestionDescription";
import { QuestionStatement } from "./QuestionStatement";
import { AnswerBox } from "./AnswerBox";
import { AnswersBox } from "./AnswersBox";
import { AnswerNumber } from "./AnswerNumber";
import { SubmitButton } from "./SubmitButton";
import { AnimatablePage } from "../AnimatablePage";
import SceneClass from "../SceneClass";
import { AnswersList } from "./AnswersList";
import { CorrectIcon } from "./CorrectIcon";
import { WrongIcon } from "./WrongIcon";

type QuestionProps = {
  id: string
  answers: number
  readonly?: boolean
  onAdvance: () => void
  onSubmit: (value: number) => void
  value?: number
  active?: boolean
  showFeedback?: boolean
  correctValues?: number[]
}

type QuestionState = {
  answer : number
}

export class Question extends SceneClass<QuestionProps,QuestionState> {
  questionBox: HTMLDivElement | {} = { };
  questionDescription: HTMLDivElement | {} = { };
  questionStatement: HTMLDivElement | {} = { };
  answerBoxes: HTMLDivElement[] | {}[]; 
  submit: HTMLDivElement | {} = { };

  constructor(props: QuestionProps) {
    super(props);
    this.answerBoxes = range(props.answers).map(v => { return {} })

    this.state = { answer : -1 }
  }

  componentDidMount() {
    this.master
      .add('question_transition_1')
      .to(this.questionBox, this.timeFrame * 0.5, { left: '0%' }, `question_transition_1`)
      .to(this.questionDescription, this.timeFrame * 0.5, { left: '0%' }, `question_transition_1+=${this.timeFrame * 0.5}`)
      .to(this.questionStatement, this.timeFrame * 0.3, { left: '0%' }, `question_transition_1+=${this.timeFrame * 0.7}`)

      .add('question_transition_3')
    range(this.props.answers)
      .map(v => this.master.to(this.answerBoxes[v], this.timeFrame * ((1 / this.props.answers) * (v + 1)), { top: '0%' }, `question_transition_3`))

    this.master
      .to(this.submit, this.timeFrame, { top: '5em' }, `question_transition_3`)
      .to(this.questionDescription, this.timeFrame * 0.7, { top: '-100%' }, `question_transition_3`)
      .to(this.questionStatement, this.timeFrame * 0.9, { top: '2rem', fontSize: '0.9em' }, `question_transition_3+=${this.timeFrame * 0.1}`)

      .add('question_transition_4')
      .to(this.questionBox, this.timeFrame, { top: '-100%' }, `question_transition_4`)
  }

  handleSubmit(cb: () => void) {
    // console.log("Submitting...")
    if (this.props.value || this.props.readonly) {
      cb();
      this.props.onAdvance();
    } else {
      if (this.state.answer !== -1) {
        !this.props.showFeedback && cb();
        this.props.onSubmit(this.state.answer);
      }
    }
  }

  render() {
    return (
      <AnimatablePage master={this.master}
        duration={this.timeFrame}
        irreversible={!this.props.readonly}
        freeze={'question_transition_4'}
        autoPlay={this.props.active}>
        {({ handleClick, handleWheel, handleTouchStart, handleTouchEnd }) => {
          return (
            <>
              <QuestionBox onWheel={handleWheel.bind(this)}
                onTouchEnd={handleTouchEnd.bind(this)}
                onTouchStart={handleTouchStart.bind(this)}
                ref={div => div && (this.questionBox = div)}>
                <QuestionDescription ref={div => div && (this.questionDescription = div)}>
                  <FormattedHTMLMessage id="q.description" />
                </QuestionDescription>
                <QuestionStatement ref={div => div && (this.questionStatement = div)}>
                  <FormattedHTMLMessage id={`${this.props.id}.statement`} />
                </QuestionStatement>
                <AnswersBox>
                  {
                    range(this.props.answers)
                      .map(value => {
                        const wrong = this.props.value
                          ? !this.props.correctValues?.includes(this.props.value) && ((value + 1) === this.props.value)
                          : false
                        const correct = this.props.value
                          ? this.props.correctValues?.includes(value + 1)
                          : false
                        return (
                          <AnswerBox key={value} ref={div => div && (this.answerBoxes[value] = div)}
                            onClick={() => !this.props.value && !this.props.readonly && this.setState({ answer: value + 1 })}
                            className={(this.state.answer === (value + 1) || this.props.value === (value+1)) ? 'active' : ''}
                            disabled={!!this.props.value || this.props.readonly}
                            wrong={wrong}
                            correct={correct}
                            style={{ 
                              width: '19%', 
                              top: '300%'
                            }}>
                            {correct && <CorrectIcon />}
                            {wrong && <WrongIcon />}
                            <AnswerNumber>
                              <FormattedHTMLMessage id={`number:${value + 1}`} />
                            </AnswerNumber>
                            <AnswersList>
                              <FormattedHTMLMessage id={`${this.props.id}.answer.${value + 1}`} />
                            </AnswersList>
                          </AnswerBox>
                        )
                      })
                  }
                </AnswersBox>
                <SubmitButton ref={div => div && (this.submit = div)}
                  className={(this.state.answer === -1 && !this.props.value) && !this.props.readonly ? 'disabled' : ''}
                  onClick={() => this.handleSubmit(handleClick)}>
                  {
                    (this.props.showFeedback && this.props.value) || this.props.readonly
                      ? <FormattedHTMLMessage id="next" />
                      : <FormattedHTMLMessage id="submit" />
                  }
                </SubmitButton>
              </QuestionBox>
            </>
          )
        }}
      </AnimatablePage>
    )
  }

}