import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { gsap } from "gsap";
gsap.registerPlugin(CSSRulePlugin);

fetch(window._env_.INTL_ENDPOINT)
  .then(res => {
    res.json()
      .then(json => {
        ReactDOM.render(<App messages={json} />, document.getElementById('root'));
      });
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
