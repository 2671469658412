import styled from 'styled-components';

export const Wall = styled.div`
  position: absolute;
  height: 100%;
  bottom: 0%;
  width: 100%;
  background-image: url('/assets/images/scenario2/wall.jpg');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
`