import styled from 'styled-components';

export const Airplane = styled.div`
  position: absolute;
  height: 60%;
  bottom: -20%;
  left: -25%;
  width: 100%;
  background-image: url(/assets/images/onboarding/airplane.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
`