import styled from 'styled-components';

export const TextStrip = styled.div` 
  width: 40%;
  position: absolute;
  top: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  left: 8%;
  z-index: 99;
`