import React from "react";
import { Linear } from "gsap";
import { FormattedHTMLMessage } from "react-intl";

import { Scenario } from "../Scenario";
import { Scene } from "../Scene";
import { AnimatablePage } from "../AnimatablePage";
import range from 'lodash/range';

import { TextStrip } from "../TextStrip";
import { TextBlock } from "../TextBlock";
import SceneClass from "../SceneClass";
import { Ground } from "./elements/Ground";
import { Airplane } from "./elements/Airplane";
import { Mouse } from "./elements/Mouse";
import { LandingStrip } from "./elements/LandingStrip";
import { AnswersBox } from "../Question/AnswersBox";
import { AnswerBox } from "../Question/AnswerBox";
import { AnswerNumber } from "../Question/AnswerNumber";
import { AnswersList } from "../Question/AnswersList";
import { SubmitButton } from "../Question/SubmitButton";
import { ChatButton } from "../Chat/ChatButton";
import { ChatIcon } from "../Chat/ChatIcon";
import { MenuIcon } from "../History/MenuIcon";
import Game from "../Game";
import { Header } from "../Intro/elements/Header";
import { BocconiLogo } from "../BocconiLogo";
import { BuiltLogoExt } from "../BuiltLogoExt";
import { AirplaneInternalBackground } from "../Intro/elements/AirplaneInternalBackground";
import { Porthole } from "../Intro/elements/Porthole";
import { Author } from "./elements/Author";
import { InputLabel } from "./elements/InputLabel";
import { TextInput } from "./elements/TextInput";

export default class Onboarding extends SceneClass<{ gameId: string }, { answer: number, teamName: string, location: string }> {
  scenario: HTMLDivElement | {} = {}
  scene_1: HTMLDivElement | {} = {};
  header: HTMLDivElement | {} = {}
  airplaneInternalBackground: HTMLDivElement | {} = {}
  porthole: HTMLDivElement | {} = {}

  airplane: HTMLDivElement | {} = {};
  landingStrip: HTMLDivElement | {} = {};
  mouse: HTMLDivElement | {} = {};

  textStrip: HTMLDivElement | {} = {};
  textBlock_1: HTMLDivElement | {} = {};
  textBlock_2: HTMLDivElement | {} = {};
  textBlock_3: HTMLDivElement | {} = {};
  textBlock_4: HTMLDivElement | {} = {};
  textBlock_5: HTMLDivElement | {} = {};
  textBlock_6: HTMLDivElement | {} = {};
  textBlock_7: HTMLDivElement | {} = {};

  constructor(props : any) {
    super(props);
    this.state = { 
      answer: -1,
      teamName: '',
      location: ''
    };
  }

  componentDidMount() {
    this.master
      .add('transition_1')
      .to(this.porthole, this.timeFrame * 0.01, { zIndex: 20 }, 'transition_1')
      .to(this.porthole, this.timeFrame * 0.8, { y: '-200%', display: 'none', ease: Linear.easeNone }, 'transition_1')
      .to(this.header, this.timeFrame * 0.1, { display: 'none' }, `transition_1+=${this.timeFrame * 0.2}`)
      .to(this.airplaneInternalBackground, this.timeFrame * 0.8, { bottom: '-300%', top: '-300%', width: '600%', left: '-150%', display: 'none', ease: Linear.easeNone }, `transition_1+=${this.timeFrame * 0.2}`)
      .to(this.textStrip, this.timeFrame, { top: '0px' }, 'transition_1')
      
      .add('transition_2')
      .to(this.airplane, this.timeFrame, { bottom: '5%' }, 'transition_2')
      .to(this.landingStrip, this.timeFrame, { bottom: '0' }, 'transition_2')
      .to(this.textBlock_1, this.timeFrame, { top: '-100%' }, 'transition_2')
      .to(this.textBlock_2, this.timeFrame, { top: '0%' }, 'transition_2')
      
      .add('transition_3')
      .to(this.airplane, this.timeFrame, { bottom: '25%' }, 'transition_3')
      .to(this.mouse, this.timeFrame * 0.5, { opacity: '0' }, 'transition_3')
      .to(this.landingStrip, this.timeFrame, { bottom: '-10%' }, 'transition_3')
      .to(this.textBlock_2, this.timeFrame, { top: '-100%' }, 'transition_3')
      .to(this.textBlock_3, this.timeFrame, { top: '0%' }, 'transition_3')
      
      .add('transition_4')
      .to(this.airplane, this.timeFrame, { height: '80%', bottom: '15%' }, 'transition_4')
      .to(this.mouse, this.timeFrame, { opacity: '1' }, 'transition_4')
      .to(this.landingStrip, this.timeFrame, { bottom: '-30%' }, 'transition_4')
      .to(this.textBlock_3, this.timeFrame, { top: '-100%' }, 'transition_4')
      .to(this.textBlock_4, this.timeFrame, { top: '0%' }, 'transition_4')

      .add('transition_5')
      .to(this.airplane, this.timeFrame, { height: '100%', bottom: '5%' }, 'transition_5')
      .to(this.landingStrip, this.timeFrame, { bottom: '-50%' }, 'transition_5')
      .to(this.textBlock_4, this.timeFrame, { top: '-100%' }, 'transition_5')
      .to(this.textBlock_5, this.timeFrame, { top: '0%' }, 'transition_5')

      .add('transition_6')
      .to(this.airplane, this.timeFrame, { height: '110%', bottom: '5%' }, 'transition_6')
      .to(this.landingStrip, this.timeFrame, { bottom: '-50%' }, 'transition_6')
      .to(this.textBlock_5, this.timeFrame, { top: '-100%' }, 'transition_6')
      .to(this.textBlock_6, this.timeFrame, { top: '0%' }, 'transition_6')

      .add('transition_7')
      .to(this.airplane, this.timeFrame, { height: '120%', bottom: '5%', left: '-20%' }, 'transition_7')
      .to(this.landingStrip, this.timeFrame, { bottom: '-70%' }, 'transition_7')
      .to(this.textBlock_6, this.timeFrame, { top: '-100%' }, 'transition_7')
      .to(this.textBlock_7, this.timeFrame, { top: '0%' }, 'transition_7')

      .add('transition_8')
      .to(this.textBlock_7, this.timeFrame, { top: '-100%' }, 'transition_8')
      .to(this.textStrip, this.timeFrame, { top: '-100%', opacity: 0 }, 'transition_8')
      .to(this.mouse, this.timeFrame * 0.5, { opacity: '0' }, 'transition_8')
      .to(this.landingStrip, this.timeFrame, { bottom: '-200%', opacity: 0 }, 'transition_8')
      .to(this.airplane, this.timeFrame * 0.8, { height: '250%', width: '300%', left: '-100%', bottom: '-100%' }, 'transition_8')
      .to(this.airplane, this.timeFrame * 0.6, { opacity: 0 }, `transition_8+=${this.timeFrame * 0.4}`)

  }

  render() {
    return (
      <AnimatablePage master={this.master} duration={this.timeFrame} freeze="">
        {({ handleWheel, handleTouchStart, handleTouchEnd, handleClick, completed}) => {
          return (
            <Game gameId={this.props.gameId}>
              {({ game }) => {
                if (completed()) {
                  game.state.currentScenario === 'onboarding' && game.closeOnboarding();
                }
                const wait = !this.master.currentLabel() ||
                  this.master.currentLabel() === 'transition_4' ||
                  (this.master.currentLabel() === 'transition_7' && !game.readOnly) ||
                  this.master.currentLabel() === 'transition_1';
                return (
                  <Scenario onWheel={ wait ? () => {} : handleWheel.bind(this)} 
                    onTouchEnd={ wait ? () => {} : handleTouchEnd.bind(this)}
                    onTouchStart={ wait ? () => {} : handleTouchStart.bind(this)}
                    ref={div => div && (this.scenario = div)}>
                    {/* SCENA 1 */}
                    <Scene ref={div => div && (this.scene_1 = div)}>
                      <Ground />
                      <LandingStrip ref={div => div && (this.landingStrip = div)}/>
                      <Airplane ref={div => div && (this.airplane = div)} />
                      <Mouse ref={div => div && (this.mouse = div)} />

                      <Header ref={div => div && (this.header = div)}>
                        <BocconiLogo />
                        <BuiltLogoExt />
                      </Header>
                      <Porthole ref={div => div && (this.porthole = div)}
                        onClick={() => {
                          !game.state.startedAt && game.start();
                          handleClick()
                        }} />
                      <AirplaneInternalBackground ref={div => div && (this.airplaneInternalBackground = div)}>
                        <Author color={game.state.color} teamName={game.state.name}/>
                      </AirplaneInternalBackground>
                    </Scene>

                    {/* TEXT */}
                    <TextStrip ref={div => div && (this.textStrip = div)}
                      style={{ left: '55%' }}>
                      <TextBlock ref={div => div && (this.textBlock_1 = div)}
                        style={{ top: '0%' }}>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.1" />
                        <br/>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.2" />
                        <br/>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.3" />
                        <br/>
                        <br/>
                        <br/>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.4" />
                      </TextBlock>
                      <TextBlock ref={div => div && (this.textBlock_2 = div)}>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.5" />
                        <br/>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.6" />
                        <br/>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.7" />
                        <br/>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.8" />
                        <br/>
                        <br/>
                        <br/>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.9" />
                      </TextBlock>
                      <TextBlock ref={div => div && (this.textBlock_3 = div)}>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.10" />
                        <br/>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.11" />
                        <br/>
                        {
                          game.state.isCoordinator 
                            ? <FormattedHTMLMessage tagName="div" id="onboarding.text.12" />
                            : <FormattedHTMLMessage tagName="div" id="onboarding.text.12-readOnly" />
                        }
                        <br/>
                        <AnswersBox style={{
                          position: 'static',
                          marginTop: '1em',
                          minHeight: 'auto'
                        }}>
                          {
                            range(2)
                              .map(value => (
                                <AnswerBox key={value}
                                  onClick={() => game.state.isCoordinator && this.setState({ answer: value + 1 })}
                                  className={this.state.answer === (value + 1) ? 'active' : ''}
                                  disabled={game.readOnly}
                                  style={{ width: '40%', top: 0 }}>
                                  <AnswerNumber>
                                    <FormattedHTMLMessage id={`number:${value + 1}`} />
                                  </AnswerNumber>
                                  <AnswersList style={{margin: 0, padding: 0}}>
                                    {
                                      game.state.isCoordinator
                                        ? <FormattedHTMLMessage id={`onboarding.answer.${value + 1}`} />
                                        : <FormattedHTMLMessage id={`onboarding.answer-readOnly.${value + 1}`} />
                                    }
                                  </AnswersList>
                                </AnswerBox>
                              ))
                          }
                        </AnswersBox>
                        <SubmitButton className={(this.state.answer === -1 && game.state.isCoordinator) ? 'disabled' : ''}
                          onClick={() => (this.state.answer !== -1 || game.readOnly) && handleClick()}
                          style={{
                            position: 'static',
                            marginTop: '1em',
                            width: '140px'
                          }}>
                          {
                            game.readOnly
                              ? <FormattedHTMLMessage id="next" />
                              : <FormattedHTMLMessage id="submit" />
                          }
                        </SubmitButton>
                      </TextBlock>
                      <TextBlock ref={div => div && (this.textBlock_4 = div)}>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.13" />
                        <br />
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.14" />
                        <br />
                        <ChatButton disabled style={{ width: '60px', position: 'static' }}>
                          <MenuIcon />
                        </ChatButton>
                        <br />
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.15" />
                        <br />
                        <br />
                        <br />
                        <br />
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.16" />
                      </TextBlock>
                      <TextBlock ref={div => div && (this.textBlock_5 = div)}>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.17" />
                        <br />
                        <ChatButton disabled style={{ width: '60px', position: 'static' }}>
                          <ChatIcon />
                        </ChatButton>
                        <br />
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.18" />
                        <br />
                        <br />
                        <br />
                        <br />
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.19" />
                      </TextBlock>
                      <TextBlock ref={div => div && (this.textBlock_6 = div)}>
                        {
                          game.readOnly
                            ? <FormattedHTMLMessage tagName="div" id="onboarding.text.20-readOnly" />
                            : <FormattedHTMLMessage tagName="div" id="onboarding.text.20" />
                        }
                        {
                          !game.readOnly && (
                            <>
                              <br />
                              <div>
                                <InputLabel>
                                  <FormattedHTMLMessage tagName="div" id="onboarding.text.21" />
                                </InputLabel>
                                <TextInput value={this.state.teamName}
                                  onChange={(e) => this.setState({ teamName: e.target.value })} />
                              </div>
                              <br />
                              <div>
                                <InputLabel>
                                  <FormattedHTMLMessage tagName="div" id="onboarding.text.22" />
                                </InputLabel>
                                <TextInput value={this.state.location}
                                  onChange={(e) => this.setState({ location: e.target.value })} />
                              </div>
                              <br />
                              <br />
                              <SubmitButton className={this.state.teamName.trim().length < 2 ? 'disabled' : ''}
                                onClick={() => {
                                  if (this.state.teamName.trim().length) {
                                    game.setTeamNameAndLocation(this.state.teamName.trim(), this.state.location.trim());
                                    handleClick();
                                  }
                                }}
                                style={{
                                  position: 'static',
                                  marginTop: '1em',
                                  width: '140px'
                                }}>
                                <FormattedHTMLMessage id="save" />
                              </SubmitButton>
                            </>
                          )
                        }
                      </TextBlock>
                      <TextBlock ref={div => div && (this.textBlock_7 = div)}>
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.25" />
                        <br />
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.26" />
                        <br />
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.27" />
                        <br />
                        <br />
                        <FormattedHTMLMessage tagName="div" id="onboarding.text.28" />
                      </TextBlock>
                    </TextStrip>

                    {
                      completed() && game.readOnly && (
                        <TextStrip ref={div => div && (this.textStrip = div)}
                          style={{ left: '55%', top: '0px' }}>
                          <TextBlock ref={div => div && (this.textBlock_1 = div)}
                            style={{ top: '0%' }}>
                            <FormattedHTMLMessage tagName="div" id="wait.for.coordinator" />
                          </TextBlock>
                        </TextStrip>
                      )
                    }

                    {/* QUESTIONS */}
                  </Scenario>
                )
              }}
            </Game>
          )
        }}
      </AnimatablePage>
    )
  }

}